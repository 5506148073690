<template>
  <!-- Data table for contct list: start -->

  <v-flex flat>
    <template>
      <v-data-table :headers="headers" :items="contactLists" sort-by="name" class="mx-5 my-2 px-5 v-sheet v-card fill-height" :loading="loading" loading-text="Loading... Please wait" fixed-header>
        <template v-slot:top>
          <v-toolbar flat>
            <!-- <h4 class="text-uppercase grey--text my-2">Contact Lists</h4> -->
            <v-alert dense text outlined :type="alertType" v-if="showAlert" class="ma-2" @click="showAlert = !showAlert" style="cursor: pointer;">{{ alertMessage }} </v-alert>
            <v-spacer></v-spacer>
            <!-- Edit Dialog Box: start -->
            <v-dialog v-model="dialog" max-width="500px" flat>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark text outlined class="mb-2 font-weight-black" v-bind="attrs" v-on="on">
                  <v-icon>mdi-account-multiple-plus-outline</v-icon>
                </v-btn>

                <!-- <v-btn color="primary" dark text class="mb-2 text-uppercase text-subtitle2 font-weight-black">Filter</v-btn> -->
              </template>
              <v-card class="rounded-0">
                <v-card-title class="text-center">
                  <h4 class="text-uppercase text-center grey--text my-2" style="width:100%;">{{ formTitle }}</h4>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-center" style="flex-direction: column;width:100%;">
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field v-model="editedItem.name" :rules="nameRules" label="List Name" outlined class="rounded-0 "></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field v-model="editedItem.description" label="Description" outlined class="rounded-0"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-select v-model="editedItem.status" :items="['Active', 'Inactive']" label="Status" outlined class="rounded-0"></v-select>
                        </v-col>
                      </v-form>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <div class="px-8 red--text">{{ dialogStatus }}</div>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" outlined text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-4" text outlined @click="save" :disabled="!valid || requestSent">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Edit Dialog Box: end -->

            <!-- Delete Dialog Box: start -->
            <v-dialog v-model="dialogDelete" max-width="500px" width="500px">
              <v-card>
                <v-card-title class="text-center red--text my-2 justify-center">Are you sure you want to delete this list?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" outlined text @click="closeDelete" style="letter-spacing:normal;">Cancel</v-btn>
                  <v-btn color="red" outlined text @click="deleteItemConfirm" style="letter-spacing:normal;">Yes</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add or remove contacts dialog -->
            <v-dialog v-model="addContactsDialog" max-width="500px" width="500px">
              <v-card>
                <v-card-title class="text-center my-2 justify-center">Add Contacts to List "{{ addContactsItem.name }}"</v-card-title>
                <v-card-text>
                  <!-- data table for contacts -->
                  <template>
                    <v-data-table
                      dense
                      v-model="addContactsSelected"
                      :headers="addContactsTableHeaders"
                      :items="addContactsTableContacts"
                      :single-select="false"
                      item-key="name"
                      show-select
                      class="elevation-1"
                      :loading="addContactsDialogLoading"
                      loading-text="Loading... Please wait"
                    >
                      <template v-slot:top> </template>
                      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                        <v-simple-checkbox :value="isSelected" :readonly="false" :disabled="item.contactListId.includes(addContactsItem.id)" @input="select($event)" :ripple="false"></v-simple-checkbox>
                      </template>
                    </v-data-table>
                  </template>
                  <!-- data table for contacts -->
                </v-card-text>
                <v-card-actions>
                  <div>{{ addContactsDialogStatus }}</div>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" outlined text @click="closeAddContactsDialog" style="letter-spacing:normal;">Cancel</v-btn>
                  <v-btn color="green" outlined text @click="addContactsToListConfirm" class="mr-4" style="letter-spacing:normal;" :disabled="requestSent">Add</v-btn>
                  <!-- <v-spacer></v-spacer> -->
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Remove contacts dialog -->
            <v-dialog v-model="removeContactsDialog" max-width="500px" width="500px">
              <v-card>
                <v-card-title class="text-center my-2 justify-center">Remove Contacts from List "{{ removeContactsItem.name }}"</v-card-title>
                <v-card-text>
                  <!-- data table for contacts -->
                  <template>
                    <v-data-table
                      dense
                      v-model="removeContactsSelected"
                      :headers="removeContactsTableHeaders"
                      :items="removeContactsTableContacts"
                      :single-select="false"
                      item-key="id"
                      show-select
                      class="elevation-1"
                      :loading="removeContactsDialogLoading"
                      loading-text="Loading... Please wait"
                    >
                      <template v-slot:top> </template>
                      <!-- <template v-slot:item.data-table-select="{ item, isSelected, select }">
                        <v-simple-checkbox :value="isSelected" :readonly="false" :disabled="item.contactListId.includes(addContactsItem.id)" @input="select($event)" :ripple="false"></v-simple-checkbox>
                      </template> -->
                    </v-data-table>
                  </template>
                  <!-- data table for contacts -->
                </v-card-text>
                <v-card-actions>
                  <div>{{ removeContactsDialogStatus }}</div>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" outlined text @click="closeRemoveContactsDialog" style="letter-spacing:normal;">Cancel</v-btn>
                  <v-btn color="red" outlined text @click="removeContactsFromListConfirm" class="mr-4" style="letter-spacing:normal;" :disabled="requestSent">Remove</v-btn>
                  <!-- <v-spacer></v-spacer> -->
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Remove contacts dialog -->
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
          <v-icon small class="mr-2" @click="addContacts(item)">
            mdi-account-plus
          </v-icon>
          <v-icon small @click="removeContacts(item)">
            mdi-account-minus
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            {{ errorStatus }}
          </v-btn>
        </template>
        <!-- SMS Sent At SLot -->
        <template v-slot:[`item.createdAt`]="{ item }">
          <span class="text-capitalize">{{ item.createdAt | formatDate }}</span>
        </template>
      </v-data-table>
    </template>
    <!-- Data table for contct list: end -->
  </v-flex>
</template>

<script>
import moment from "moment";
export default {
  props: ["allContacts", "contactsRequest", "allContactLists", "contactListRequest"],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "List Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      // { text: "Members", value: "members" },
      { text: "Description", value: "description" },
      { text: "Created", value: "createdAt" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    contactLists: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      description: "",
      status: "Active",
    },
    defaultItem: {
      name: "",
      description: "",
      status: "Active",
    },
    // form values
    valid: true,
    requestSent: false,
    nameRules: [(v) => !!v || "Name is required"],
    // table loading
    loading: true,
    errorStatus: "Lodaing....",
    // alert
    alertType: "success",
    alertMessage: "Contact List created",
    showAlert: false,
    dialogStatus: "",

    // add contacts to list
    addContactsDialog: false,
    addContactsDialogStatus: "",

    addContactsItem: { name: "" },
    addContactsSelected: [],
    addContactsTableHeaders: [
      { text: "Name", align: "start", sortable: true, value: "name" },
      { text: "Number", value: "mobile" },
    ],
    addContactsTableContacts: [],

    // remove contacts from list
    removeContactsDialog: false,
    removeContactsDialogStatus: "",
    removeContactsDialogLoading: true,
    removeContactsItem: { name: "" },
    removeContactsSelected: [],
    removeContactsTableHeaders: [
      { text: "Name", align: "start", sortable: true, value: "name" },
      { text: "Number", value: "mobile" },
    ],
    removeContactsTableContacts: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Contact List" : "Edit Contact List";
    },
    addContactsDialogLoading() {
      return this.addContactsTableContacts.length > 1 ? false : true;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "contactLists.length": function() {
      if (this.contactLists.length < 1) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    allContactLists: function(val) {
      this.contactLists = val;
    },
    allContacts: function(val) {
      this.addContactsTableContacts = val;
      if (this.addContactsTableContacts < 1) {
        this.addContactsDialogLoading = true;
      } else {
        this.addContactsDialogLoading = false;
      }
    },
  },

  created() {
    if (this.allContactLists.length < 1 && !this.contactListRequest) this.initialize();
    if (this.contactLists.length < 1) this.contactLists = this.allContactLists;
    this.addContactsTableContacts = this.allContacts;
  },

  methods: {
    initialize() {
      this.loading = true;
      this.contactLists = [];

      this.axios
        .get("/api/contactlists")
        .then((res) => {
          this.contactLists = res.data.contactLists;
          this.loading = false;
        })
        .catch((err) => {
          this.errorStatus = "An Error Has Accoured, Reload Data?";
          this.loading = false;
        });
    },
    // add contacts section start
    addContacts(item) {
      this.addContactsDialog = true;
      this.addContactsItem = Object.assign({}, item);
      if (this.addContactsTableContacts.length > 0) return;
      this.addContactsDialogLoading = true;
      this.axios
        .get("/api/contacts/")
        .then((res) => {
          this.addContactsDialogLoading = false;
          this.addContactsTableContacts = res.data.contacts;
        })
        .catch((err) => console.log(err));
    },
    addContactsToListConfirm() {
      this.requestSent = true;
      let contactIds = this.addContactsSelected.map((contact) => contact.id);
      this.axios
        .post("/api/contactlists/addcontacts", { contactIds, contactListId: this.addContactsItem.id })
        .then((res) => {
          if (!res.data.success) {
            this.addContactsDialogStatus = res.data.message;
            this.requestSent = false;
            return;
          }
          this.alertMessage = "Contacts added to list";
          this.alertType = "success";
          this.showAlert = true;
          this.addContactsTableContacts.forEach((contact) => {
            if (contactIds.includes(contact.id)) {
              if (!contact.contactListId.includes(this.addContactsItem.id)) {
                contact.contactListId.push(this.addContactsItem.id);
              }
            }
          });
          this.closeAddContactsDialog();
        })
        .catch((err) => {
          console.log(err);
          this.alertMessage = "Contacts could not be added";
          this.alertType = "error";
          this.showAlert = true;
          this.closeAddContactsDialog();
        });
    },
    closeAddContactsDialog() {
      this.requestSent = false;
      this.addContactsDialog = false;
      this.addContactsSelected = [];
      this.addContactsItem = { name: "" };
      // console.log(this.addContactsTableContacts);
    },
    // add contacts section end

    // remove contacts section start
    removeContacts(item) {
      this.removeContactsDialog = true;
      this.removeContactsItem = Object.assign({}, item);
      if (this.addContactsTableContacts.length > 0) {
        this.removeContactsTableContacts = this.addContactsTableContacts.filter((contact) => contact.contactListId.includes(this.removeContactsItem.id));
        this.removeContactsDialogLoading = false;
        return;
      }
      this.addContactsDialogLoading = true;
      this.axios
        .get("/api/contacts/")
        .then((res) => {
          this.removeContactsTableContacts = res.data.contacts.filter((contact) => contact.contactListId.includes(item.id));
          this.removeContactsDialogLoading = false;
        })
        .catch((err) => console.log(err));
    },
    removeContactsFromListConfirm() {
      this.requestSent = true;
      let contactIds = this.removeContactsSelected.map((contact) => contact.id);
      this.axios
        .post("/api/contactlists/removecontacts", { contactIds, contactListId: this.removeContactsItem.id })
        .then((res) => {
          if (!res.data.success) {
            this.removeContactsDialogStatus = res.data.message;
            this.requestSent = false;
            return;
          }
          this.alertMessage = "Contacts removed from list";
          this.alertType = "warning";
          this.showAlert = true;
          this.addContactsTableContacts.forEach((contact) => {
            if (contactIds.includes(contact.id)) {
              let index = contact.contactListId.indexOf(this.removeContactsItem.id);
              if (index > -1) {
                contact.contactListId.splice(index, 1);
              }
            }
          });
          this.closeRemoveContactsDialog();
        })
        .catch((err) => {
          console.log(err);
          this.alertMessage = "Contacts could not be removed";
          this.alertType = "error";
          this.showAlert = true;
          this.closeRemoveContactsDialog();
        });
    },
    closeRemoveContactsDialog() {
      this.requestSent = false;
      this.removeContactsDialog = false;
      this.removeContactsSelected = [];
      this.removeContactsItem = { id: "", name: "" };
    },
    // remove contacts section end

    editItem(item) {
      this.editedIndex = this.contactLists.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.contactLists.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.showAlert = true;
      this.alertMessage = "Deleting contact list...";
      this.alertType = "error";
      this.axios
        .put("/api/contactlists/contactlist/delete", {
          id: this.editedItem.id,
        })
        .then((res) => {
          if (!res.data.success) {
            this.alertMessage = res.data.message;
            this.closeDelete();
            return;
          }
          this.alertMessage = "Contact List deleted successfully";
          this.contactLists.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch((err) => {
          this.closeDelete();
          this.alertMessage = "Contact List could not be deleted";
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.dialogStatus = "";
      let validated = this.$refs.form.validate();
      if (!validated) return;

      this.requestSent = true;
      // updating contact list
      if (this.editedIndex > -1) {
        this.dialogStatus = "Updating contact list...";

        this.axios
          .put("/api/contactlists/contactlist/update", {
            id: this.editedItem.id,
            name: this.editedItem.name,
            description: this.editedItem.description,
            status: this.editedItem.status == "Active" ? true : false,
          })
          .then((res) => {
            if (!res.data.success) {
              this.dialogStatus = res.data.message;
              this.requestSent = false;
              return;
            }
            this.close();
            this.showAlert = true;
            this.alertMessage = "Contact List updated successfully";
            Object.assign(this.contactLists[this.editedIndex], this.editedItem);
            this.requestSent = false;
          })
          .catch((err) => {
            this.close();
            this.showAlert = true;
            this.alertMessage = "Contact List could not be updated";
            this.alertType = "warning";
            this.requestSent = false;
          });
      } else {
        // creating new contact list
        this.dialogStatus = "Creating contact list...";
        this.axios
          .post("/api/contactlists/create", {
            name: this.editedItem.name,
            description: this.editedItem.description,
            status: this.editedItem.status == "Active" ? true : false,
          })
          .then((res) => {
            if (!res.data.success) {
              this.dialogStatus = res.data.message;
              this.requestSent = false;
              return;
            }
            this.close();
            this.showAlert = true;
            this.alertMessage = "Contact List created successfully";
            this.contactLists.push(this.editedItem);
            this.requestSent = false;
          })
          .catch((err) => {
            this.close();
            this.showAlert = true;
            this.alertMessage = "Contact List could not be created";
            this.alertType = "warning";
            this.requestSent = false;
          });
      }
    },
  },

  filters: {
    formatDate: function(date) {
      // return moment(date).fromNow();
      return moment(date).format("Do MMM YY");
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
::v-deep .v-dialog {
  border-radius: 0px;
}
</style>
