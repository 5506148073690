<template>
  <v-sheet color="white" elevation="3" rounded class="d-flex align-start justify-center mr-2 pa-2" style="min-height: 90vh; width: 100%">
    <single-sms-dialog :show="show_sms_dialog" :mobile_number="mobile_number" :contact_id="contact_id" @close-sms-dialog="(v) => (show_sms_dialog = v)"></single-sms-dialog>

    <!-- loading overlay -->
    <v-overlay :value="loading" style="z-index: 20001">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <!-- Info message snackbar -->
    <v-snackbar v-model="showSnackbar" timeout="-1" :vertical="true" app top right style="opacity: 1; z-index: 2005" content-class="main-snackbar-content" elevation="3" max-width="150">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <template>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="contacts"
        :options.sync="options"
        :server-items-length="contacts_length"
        item-key="id"
        class="rounded-2"
        height="70vh"
        show-select
        :single-select="singleSelect"
        show-expand
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        fixed-header
        dense
        style="border: none; width: 100%"
        :footer-props="{ 'items-per-page-options': [50, 100, 250, 500] }"
      >
        <template v-slot:top>
          <!-- filters start -->
          <v-row v-if="showFilters" class="px-5 pb-4 align-baseline" no-gutters>
            <!-- filters section: strat -->
            <v-col cols="12" xs="12" sm="12" md="9">
              <v-row class="align-center my-0 py-0" v-for="(filter, index) in includeFilters" :key="index">
                <!-- Councils search -->
                <v-col cols="12" md="3" class="ma-0 pa-0">
                  <v-select
                    class="rounded-0 text-capitalize small-font"
                    v-model="includeFilters[index].column"
                    :items="includeFilterColumns"
                    label="column"
                    hide-details
                    auto-select-first
                    dense
                    solo
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2" class="ma-0 pa-0">
                  <v-select
                    class="rounded-0 small-font"
                    v-model="includeFilters[index].condition"
                    :items="includeFilterIntFields.includes(includeFilters[index].column) ? includeFilterIntConditions : includeFilterTextConditions"
                    label="condition"
                    hide-details
                    auto-select-first
                    dense
                    solo
                  ></v-select>
                </v-col>

                <!-- Case: condition === contains -->
                <v-col cols="12" xs="12" sm="12" md="6" class="mx-0 pa-0" v-if="includeFilters[index].condition == 'contains'">
                  <v-autocomplete
                    v-if="includeFIltersShowAutocomplete.includes(includeFilters[index].column)"
                    multiple
                    class="rounded-0 text-capitalize small-font"
                    @focus="getSuggestionsFromStore(includeFilters[index])"
                    v-model="includeFilters[index].value"
                    @keyup="getSuggestions(includeFilters[index], $event.target.value)"
                    :items="suggestions"
                    :item-text="includeFilters[index].column"
                    label="value"
                    :return-object="false"
                    hide-details
                    dense
                    solo
                    autocomplete="false"
                    small-chips
                    deletable-chips
                  ></v-autocomplete>
                  <v-combobox
                    v-else
                    multiple
                    class="rounded-0 text-capitalize small-font"
                    v-model="includeFilters[index].value"
                    @keyup="getSuggestions(includeFilters[index], $event.target.value)"
                    @focus="getSuggestionsFromStore(includeFilters[index])"
                    :items="suggestions"
                    :item-text="includeFilters[index].column"
                    label="value"
                    :return-object="false"
                    hide-details
                    dense
                    solo
                    autocomplete="false"
                    small-chips
                    deletable-chips
                  ></v-combobox>
                </v-col>

                <!-- Case: condition === range -->
                <v-col cols="12" xs="12" sm="12" md="6" class="mx-0 px-0 d-flex" v-if="includeFilters[index].condition == 'range'">
                  <v-text-field type="number" class="rounded-0 text-capitalize small-font" v-model="includeFilters[index].range[0]" label="Min" hide-details dense solo></v-text-field>
                  <v-text-field type="number" class="rounded-0 text-capitalize small-font" v-model="includeFilters[index].range[1]" label="Max" hide-details dense solo></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" class="mx-0 px-0 py-0 small-font">
                  <v-btn color="red" dark text outlined class="py-5 rounded-0 text-capitalize" @click="includeFilters.length > 1 ? includeFilters.splice(index, 1) : ''">
                    <v-icon>mdi-filter-minus-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <!-- action buttons -->
            <v-col cols="12" xs="12" sm="12" md="3" class="d-flex flex-wrap justify-end align-center">
              <v-btn small color="primary" dark text outlined class="py-5" @click="loadContacts()" :loading="suggestionRequestSent">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn small color="primary" dark text outlined class="py-5" @click="includeFilters.push({ column: '', condition: 'contains', value: [], range: [null, null] })">
                <v-icon>mdi-filter-plus-outline</v-icon>
              </v-btn>
              <v-btn small color="primary" dark text outlined class="py-5" @click="includeFilters = [{ column: '', condition: 'contains', value: [], range: [null, null] }]">
                <v-icon>mdi-filter-remove-outline</v-icon>
              </v-btn>
              <v-btn small color="primary" dark text outlined class="py-5" @click="refresh()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-col>
            <!-- filters section: end -->
          </v-row>
          <!-- filters end -->
        </template>

        <!-- is Opt Out -->
        <template v-slot:[`item.isOptOut`]="{ item }">
          <span style="width: 100px">{{ item.isOptOut ? "Yes" : "No" }}</span>
        </template>

        <!-- is Opt Out -->
        <template v-slot:[`item.formattedMobile`]="{ item }">
          <a :href="`tel:+${item.formattedMobile}`">{{ item.formattedMobile }}</a>
        </template>

        <!-- expansion template: start -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="py-8 px-5">
              <template>
                <v-col class="py-1" cols="2"><b>Mobile:</b></v-col>
                <v-col class="py-1" cols="4">
                  <div v-if="item.formattedMobile">
                    <a :href="`tel:+${item.formattedMobile}`">{{ item.formattedMobile }}</a>
                    <span class="pl-2" v-if="item.contact_data && item.contact_data.last_called">Last Marked: ({{ get_date(item.contact_data.last_called) }})</span>
                  </div>
                  <div>NA</div>
                </v-col>
                <v-col class="py-1" cols="6">
                  <div v-if="item.formattedMobile" class="btns-div">
                    <v-btn color="white" class="ma-1 px-1" small elevation="2" @click="send_sms(item)"> <v-icon class="mr-1" small>mdi-message-text-outline</v-icon>Send SMS</v-btn>
                    <v-btn :color="buttonColor(item)" class="ma-1 px-2" small elevation="2" @click="mark_contacted(item)">
                      <v-icon class="mr-1" small>mdi-check-circle-outline</v-icon>
                      {{ item.contact_data && item.contact_data.last_called ? "Unmark Contacted" : "Mark Contacted" }}
                    </v-btn>
                  </div>
                </v-col>
              </template>

              <template>
                <v-col class="py-1" cols="2"><b>Email:</b></v-col>
                <v-col class="py-1" cols="10">
                  <div>{{ item.email || "NA" }}</div>
                </v-col>
              </template>

              <template>
                <v-col class="py-1" cols="2"><b>Phone:</b></v-col>
                <v-col class="py-1" cols="10">
                  <div>{{ item.phone || "NA" }}</div>
                </v-col>
              </template>

              <template>
                <v-col class="py-1" cols="2"><b>Markets:</b></v-col>
                <v-col class="py-1" cols="10">
                  <div>{{ item.markets?.join(" | ") || "NA" }}</div>
                </v-col>
              </template>

              <template>
                <v-col class="py-1" cols="2"><b>Reference:</b></v-col>
                <v-col class="py-1" cols="10">
                  <div>
                    <a v-if="item.reference" :href="item.reference" target="_blank"> {{ item.reference }}</a> <span v-else>NA</span>
                  </div>
                </v-col>
              </template>
            </v-row>
          </td>
        </template>

        <!-- expansion template: end -->
      </v-data-table>
    </template>
  </v-sheet>
</template>

<script>
import SingleSMSDialog from "../burstsms/SingleSMSDialog.vue";
import moment from "moment";
export default {
  name: "ComponentDatatableWithFilters",
  props: ["headers"],
  components: {
    "single-sms-dialog": SingleSMSDialog,
  },
  data: () => ({
    // table fields
    contacts: [],
    contacts_length: 0,
    options: {
      page: 1,
      itemsPerPage: 250,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    singleSelect: false,
    selected: [],
    singleExpand: true,
    expanded: [],

    // filter fields
    showFilters: true,
    includeFilters: [{ column: "state", condition: "contains", value: ["QLD"], range: [null, null] }],
    includeFiltersRow: { column: "", condition: "contains", value: [], range: [null, null] },
    includeFilterTextConditions: ["contains"],
    includeFilterIntConditions: ["range", "contains"],
    includeFilterTextFields: [],
    includeFilterIntFields: ["size", "postal_code"],
    includeFilterColumns: [
      { text: "Name", value: "name" },
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Mobile", value: "formattedMobile" },
      { text: "Markets", value: "markets" },
      { text: "Type", value: "type" },
      { text: "Category", value: "category" },
      // { text: "Phone Number", value: "phone" },
      // { text: "Email", value: "email" },
      // { text: "Opted Out", value: "isOptOut" },
      // { text: "Agency", value: "agency" },
      { text: "Agency", value: "agency" },
    ],
    includeFiltersStoreSuggestions: ["markets", "isOptOut", "type", "category"],
    includeFIltersShowAutocomplete: ["markets"],
    suggestions: [],
    suggestionRequestSent: false,

    // alert & progress fields
    requestSent: false,
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    // dialog fields
    show_sms_dialog: false,
    result: "",
    mobile_number: "",
    contact_id: null,
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    // new fields
    options() {
      this.loadContacts();
    },
    selected(newval) {
      this.$emit("datatable-selection-updated", newval);
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    // new methods
    async initialize() {
      this.options.page = 1;
      this.includeFilters = [{ column: "", condition: "contains", value: [], range: [null, null] }];
      await this.loadContacts();
    },

    async refresh() {
      await this.loadContacts();
    },

    // new functions for component
    async loadContacts() {
      try {
        // checking for other requests
        if (this.requestSent) return;
        this.requestSent = true;
        await new Promise((r) => setTimeout(r, 200));
        // sending request for contacts
        let res = await this.axios.post("/api/contacts/getcontacts", {
          options: this.options,
          filters: this.includeFilters,
        });
        this.requestSent = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.contacts = res.data.contacts;
        this.contacts_length = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message || "Contacts could not be loaded: check your internet connection");
      }
    },

    // getting suggestions while typing
    async getSuggestions(input_filter, input_value) {
      if (input_value.length < 3 || !input_filter.column || this.includeFiltersStoreSuggestions.includes(input_filter.column)) return;
      this.suggestionRequestSent = true;
      let filter = Object.assign({}, input_filter);
      filter.value = input_value;
      let res = await this.axios.post("/api/contacts/getsuggestions", { filter });
      this.suggestionRequestSent = false;
      if (!res.data.success) return;
      this.suggestions = res.data.suggestions;
    },

    // getting suggestions or combobox-items for predefined values like markets
    async getSuggestionsFromStore(filter) {
      filter.value = [];
      this.suggestions = [];
      let column = filter.column;

      if (this.includeFiltersStoreSuggestions.includes(column)) {
        if (column.toLowerCase() == "markets") column = "suburb";
        this.suggestions = this.$store.getters.getFilterItems[column];
        if (column == "suburb") {
          this.suggestions = this.$store.getters.getFilterItems[column].map((suburb) => suburb.suburb + ", " + suburb.state + ", " + suburb.postcode);
        }
        if (column == "isOptOut") {
          this.suggestions = ["Yes", "No"];
        }
      }
    },

    clear_selection() {
      this.selected = [];
    },

    // snackbar setting function
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    async send_sms(item) {
      try {
        this.mobile_number = item.formattedMobile;
        this.contact_id = item.id;
        this.show_sms_dialog = true;
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message || "Message dialog could not be opened, try reloading the page please");
      }
    },

    async mark_contacted(item) {
      try {
        // checking for other requests
        if (this.requestSent) return;
        this.requestSent = true;
        await new Promise((r) => setTimeout(r, 200));

        const contact_id = item.id;
        const mark = item.contact_data && item.contact_data.last_called ? false : true;
        // sending request for contacts
        let res = await this.axios.post("/api/contacts/updatecontactlastcalldate", { contact_id, mark });
        this.requestSent = false;
        if (!res.data.success) throw new Error(res.data.message);

        // setting values in contact array
        const index = this.contacts.findIndex((c) => c.id == contact_id);
        mark ? (this.contacts[index].contact_data = res.data.contact_data) : (this.contacts[index].contact_data = null);
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message || "Contacts could not be loaded: check your internet connection");
      }
    },
    
    buttonColor(item) {
      if (!item.contact_data || !item.contact_data.last_called) {
        return "white"; // Default color if no date is set
      }

      // const lastCalledDate = moment(item.contact_data.last_called).tz("Australia/Sydney");
      // const now = moment().tz("Australia/Sydney");
      const lastCalledDate = moment(item.contact_data.last_called);
      const now = moment();
      const daysPassed = now.diff(lastCalledDate, "days");

      if (daysPassed < 3) {
        return "#ff6257";
      } else if (daysPassed >= 3 && daysPassed <= 7) {
        return "#ff9800";
      } else if (daysPassed > 7 && daysPassed <= 30) {
        return "#8bc34a";
      } else {
        return "white"; // Default color for cases outside the specified ranges
      }
    },

    get_date(dt) {
      try {
        return moment(dt).format("DD MMM YYYY");
      } catch (error) {
        console.log(error);
        return dt;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
.btns-div .v-btn {
  text-transform: initial;
  width: 140px;
  float: right;
}
.btns .v-btn .v-icon {
  width: 20px;
}
* {
  letter-spacing: normal;
}
</style>
