<template>
  <v-row>
    <!-- Tabs Col Start -->
    <v-col cols="12" sm="12" md="12">
      <v-card class="mx-2">
        <v-overlay :value="loading" :z-index="20004">
          <v-snackbar v-model="loading" timeout="-1" centered>
            <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
            <span class="py-2 px-2">Processing</span>
          </v-snackbar>
        </v-overlay>

        <!-- Snackbar for small info alerts -->
        <v-snackbar
          :value="showSnackbar"
          timeout="-1"
          :vertical="true"
          app
          bottom
          right
          style="opacity:0.9;z-index:2005"
          content-class="main-snackbar-content d-flex flex-column align-start justify-start"
        >
          <div class="py-0" style="font-size: 13px;">{{ snackbarText }}</div>
          <div class="py-1 text-capitalize " style="font-size: 13px;">
            <template v-if="typeof snackbarText2 == 'string'">
              <div>{{ snackbarText2 }}</div>
            </template>
            <template v-else>
              <template v-for="(value, key) in snackbarText2">
                <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</div>
              </template>
            </template>
          </div>
          <template v-slot:action="">
            <v-btn small color="blue" text @click="showSnackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>

        <v-container fluid>
          <!-- FILTERS ROW START-->
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-text-field
                class="rounded-0"
                label="Search campaigns by title"
                filled
                v-model="search"
                @keyup.enter="searchCampaigns()"
                dense
                solo
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-select
                :items="lists"
                item-text="name"
                item-value="id"
                class="rounded-0"
                label="Lists / Audiances"
                :return-object="false"
                filled
                v-model="selected_list"
                @change="refreshCampaigns()"
                dense
                solo
                clearable
                hide-details
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="4" class="d-flex justify-end">
              <v-btn color="primary" dark text outlined class="font-weight-black " @click="initialize()"><v-icon>mdi-refresh</v-icon></v-btn>
              <v-btn color="primary" dark text outlined class="font-weight-black" @click="sendNewCampaign()"><v-icon>mdi-plus-thick</v-icon></v-btn>
            </v-col>
            <!-- Add / Edit Modal -->
          </v-row>
          <!-- FILTERS ROW END-->
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                v-model="selected"
                :options.sync="options"
                :server-items-length="total_campaigns"
                :headers="headers"
                :items="campaigns"
                :search="search"
                item-key="id"
                sort-by="id"
                class=""
                outlined
                show-expand
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                fixed-header
                dense
                flat
                :items-per-page="500"
                :footer-props="{ 'items-per-page-options': [5, 10, 20, 30, 50, 500] }"
              >
                <!-- expansion template: start -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-simple-table dense class="ma-5" flat>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="(value, key) in item" :key="key">
                            <template v-if="!excludeShow.includes(key)">
                              <td style="width:200px" class="font-weight-bold text-capitalize">{{ key | removeUnderscore }}</td>
                              <td v-if="datetime_fields.includes(key)">
                                {{ value | formatDate }}
                              </td>
                              <td v-else-if="type(value) != 'object'">
                                {{ value }}
                              </td>

                              <td v-else>
                                <template v-for="(ovalue, okey) in value">
                                  <div :key="okey" class="py-1" v-if="!excludeShow.includes(okey)">
                                    <span>
                                      <b class="text-capitalize">{{ okey | removeUnderscore }}:</b>
                                    </span>
                                    <span class="ml-2">{{ ovalue }}</span>
                                  </div>
                                </template>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
                <!-- expansion template: end -->

                <!-- Action Slot -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu bottom offset-x left dense>
                    <!-- Options Menu Button -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="" color="blue" v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                    </template>
                    <!-- Option Menu List -->
                    <v-list class="rounded-0" dense>
                      <v-list-item link @click="viewCampaign(item)">
                        <v-list-item-title>Edit / View Campaign</v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="deleteCampaign(item.id)">
                        <v-list-item-content>
                          <v-list-item-title class="item-actions-menu">
                            <span>Delete Campaign</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item :disabled="item.status !== 'save'" @click="sendCampaign(item.id)">
                        <v-list-item-content>
                          <v-list-item-title class="item-actions-menu">
                            <span>Send Campaign</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <template v-slot:[`item.create_time`]="{ item }">
                  <span class="smaller-font">{{ item.create_time | formatDatetime }}</span>
                </template>
                <template v-slot:[`item.send_time`]="{ item }">
                  <span class="smaller-font">{{ item.send_time | formatDatetime }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <!-- Tabs Col End -->
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  name: "MailChimpCampaigns",
  data: () => ({
    campaigns: [],

    defaultContact: {
      id: "",
      email_address: "",
      merge_fields: {
        FNAME: "",
        LNAME: "",
        MARKETS: "[]",
        PHONE: "",
      },
    },
    dialogContact: {
      id: "",
      email_address: "",
      merge_fields: {
        FNAME: "",
        LNAME: "",
        MARKETS: "[]",
        PHONE: "",
      },
    },

    lists: [],
    selectedList: "",
    activeList: "agents",
    contacts: [],
    contactsCount: 0,

    // table data
    headers: [
      { text: "Title", value: "settings.title", sortable: false },
      { text: "List", value: "recipients.list_name", sortable: false },
      { text: "Recipients", value: "recipients.recipient_count", sortable: false },
      { text: "Created", value: "create_time", width: 160, sortable: true },
      { text: "Sent", value: "send_time", width: 160, sortable: true },
      { text: "Status", value: "status", sortable: false },
      { text: "Details", value: "data-table-expand", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    selected: [],
    total_campaigns: 0,
    options: {
      page: 1,
      itemsPerPage: 100,
      itemsLength: 0,
      sortBy: ["create_time"],
      sortDesc: [true],
    },
    defaultOptions: {
      page: 1,
      itemsPerPage: 100,
      itemsLength: 0,
      sortBy: ["create_time"],
      sortDesc: [false],
    },

    selected: [],
    search: "",
    lists: [],
    selected_list: "",

    // listing's detail view attributes
    excludeShow: ["id", "ADDRESS", "BIRTHDAY"],

    // table attributes
    singleSelect: false,
    singleExpand: true,
    expanded: [],
    errorStatus: "",
    requestSent: false,
    showFilters: false,

    // alerts
    snackbar: true,
    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    //table expanded fields
    datetime_fields: ["create_time"],
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    // new fields
    options() {
      this.getCampaigns();
    },
  },
  async mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.options.page = 1;
      this.options.itemsPerPage = this.defaultOptions.itemsPerPage;
      this.search = "";
      this.selected_list = "";
      await this.getLists();
      await this.getCampaigns();
    },
    async getLists() {
      try {
        this.requestSent = true;
        let res = await this.axios.get("/api/mailchimp/getlists");
        this.requestSent = false;

        if (!res.data.success) {
          return this.setSnackbar(true, "", res.data.message);
        }
        this.lists = res.data.lists;
      } catch (error) {
        this.requestSent = false;
        this.setSnackbar(true, "Error", "Unable to get mailchimp lists / audiances - Kindly check your internet connection");
        console.log(error);
      }
    },
    async getCampaigns() {
      try {
        this.requestSent = true;
        this.headers[3].sortable = true; //enabling create_time sorting (search function compatibility)
        this.headers[4].sortable = true; //enabling send_time sorting (search function compatibility)
        let list_id = this.selected_list ? this.selected_list : "";
        let res = await this.axios.post("/api/mailchimp/getcampaigns", { options: this.options, list_id });
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "", res.data.message);
        }

        this.campaigns = res.data.campaigns;
        this.total_campaigns = res.data.total_items;
      } catch (error) {
        this.requestSent = false;
        this.setSnackbar(true, "Error", "Unable to send campaigns request - Kindly check your internet connection");
        console.log(error);
      }
    },
    async searchCampaigns() {
      try {
        if (!this.search || this.search.length < 3 || this.requestSent) {
          return this.setSnackbar(true, "Alert", "Search keyword should be greater than 2 letters");
        }
        this.requestSent = true;
        this.headers[3].sortable = false; //disabling create_time sorting
        this.headers[4].sortable = false; //disabling send_time sorting
        let res = await this.axios.post("/api/mailchimp/searchcampaigns", { options: this.options, query: this.search });
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "", res.data.message);
        }

        this.campaigns = res.data.campaigns;
        this.total_campaigns = res.data.total_items;
        //this.options.itemsPerPage = this.total_campaigns;
      } catch (error) {
        this.requestSent = false;
        this.setSnackbar(true, "Error", "Unable to send campaigns request - Kindly check your internet connection");
        console.log(error);
      }
    },
    refreshCampaigns() {
      this.options.page = 1;
      this.getCampaigns();
    },
    async sendCampaign(Id) {
      try {
        if (!Id) {
          return this.$store.dispatch("setSnackBar", "No campaign found");
        }
        this.requestSent = true;
        let campaignId = Id;
        let res = await this.axios.post("/api/mailchimp/sendcreatedcampaign", { campaignId });
        this.$store.dispatch("setSnackBar", res.data.message);
        this.requestSent = false;
        if (res.data.success) this.getCampaigns();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.$store.dispatch("setSnackBar", "Unable to send campaign request - Kindly check your internet connection");
      }
    },
    async sendNewCampaign() {
      this.$router.push({ name: "MailchimpSendEmail" });
    },
    async deleteCampaign(id) {
      try {
        if (!id) {
          return this.setSnackbar(true, "Error", "Invalid Campaign");
        }

        this.requestSent = true;
        const campaignId = id;
        const res = await this.axios.post("/api/mailchimp/deletecampaign", { campaignId });
        this.requestSent = false;
        this.setSnackbar(true, "Alert", res.data.message);

        if (!res.data.success) return;

        this.campaigns = this.campaigns.filter((c) => c.id != campaignId);
        this.total_campaigns--;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.$store.dispatch("setSnackBar", "Unable to send delete campaign request - Kindly check your internet connection");
      }
    },
    setSnackbar(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    type(value) {
      return typeof value;
    },
    viewCampaign(campaign) {
      console.log(campaign);
      if (!campaign.web_id) return;
      let url = `https://us10.admin.mailchimp.com/campaigns/edit?id=${campaign.web_id}`;
      window.open(url, "_blank");
    },
  },
  filters: {
    removeUnderscore(str) {
      return str.replace(/_/g, " ");
    },
    formatDate: function(date) {
      try {
        let m = moment(date);
        return `${m.format("YYYY MMM DD HH:mm:ss")} (${m.fromNow()})`;
      } catch (e) {
        return date;
      }
    },
    formatDatetime(datetime) {
      try {
        return moment(datetime).format("DD MMM YY (HH:mma) ");
      } catch (e) {
        return datetime;
      }
    },
  },
};
</script>

<style scoped>
.table-link {
  text-decoration: none;
}
::v-deep .v-select.v-input--dense .v-chip {
  margin-top: 2px;
  margin-bottom: 2px;
}
::v-deep .v-select__selections {
  padding: 5px 0px;
}
::v-deep .v-snack__content {
  display: flex;
  align-items: center;
}
::v-deep .gm-style-iw button {
  display: none !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .item-actions-menu {
  display: flex;
  justify-content: flex-start;
}
::v-deep .item-actions-menu i {
  margin-right: 8px;
}
.smaller-font {
  font-size: 10px;
}
</style>
