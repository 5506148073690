<template>
  <!-- View Layout -->
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <!-- View header -->

    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
          <span class="pa-2 text-center" style="line-height: 2em">Processing...</span>
        </div>
        <div class="py-2 d-flex align-baseline justify-center"></div>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar :value="showSnackbar" timeout="2000" :vertical="true" app bottom right style="opacity: 1; z-index: 2005" content-class="main-snackbar-content" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template v-if="typeof snackbarText2 == 'string'">
          <div>{{ snackbarText2 }}</div>
        </template>
        <template v-else>
          <template v-for="(value, key) in snackbarText2">
            <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</div>
          </template>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Input Column -->
    <v-col cols="12" md="12" class="pa-0">
      <v-sheet color="white" elevation="3" rounded class="mr-1 pb-5 column-content" style="min-height: 90vh">
        <!-- filters start -->
        <v-row class="px-5 pt-5 pb-0" no-gutters align="stretch">
          <!-- settings column -->
          <v-col cols="12" md="4" class="settings-div pr-md-5 pr-sm-0 mb-4">
            <v-sheet color="bsprimary2" elevation="3" rounded class="pa-4 mb-2 d-flex flex-column align-center justify-start" height="100%">
              <h5 class="mb-4 text-center" style="width: 100%">My Profile</h5>

              <v-avatar size="62" class="mb-1" style="border: 1px solid #bbbbbb">
                <!-- <img :src="require('@/assets/avatars/' + $store.getters.getUser.image)" alt="John" /> -->
                <img :src="require('@/assets/avatars/' + 'avatar2.png')" alt="John" />
              </v-avatar>

              <h5 class="mb-1 text-center" style="width: 100%">{{ $store.getters.getUser.firstName + " " + $store.getters.getUser.lastName }}</h5>
              <h5 class="mb-1 text-center" style="width: 100%">{{ $store.getters.getUser.email }}</h5>

              <div class="w-100 px-0 py-4 d-flex flex-row flex-wrap align-baseline justify-center">
                <v-btn color="white" class="mx-1 my-1" small elevation="2" :to="{ name: 'ChangeSettings' }">
                  <v-icon class="mr-1" small>mdi-account-edit</v-icon>
                  Profile Settings
                </v-btn>

                <v-btn color="white" class="mx-1 my-1" href="https://remap.ai/contact-us/" small elevation="2">
                  <v-icon class="mr-1" small>mdi-help-circle-outline</v-icon>
                  <span>Help & Support</span>
                </v-btn>
                <!-- <v-btn color="white" class="mx-1 my-1" :to="{ name: 'ChangeSettings' }" small elevation="2">
                  <v-icon class="mr-1" small>mdi-help-circle-outline</v-icon>
                  <span>Help & Support</span>
                </v-btn> -->
                <!-- <v-btn color="white" class="mx-1 my-1" :to="{ name: 'UserSubscriptions' }" small elevation="2">
                  <v-icon class="mr-1" small>mdi-bullhorn</v-icon>
                  <span>Subscriptions</span>
                </v-btn>
                <v-btn color="white" class="mx-1 my-1" :to="{ name: 'UserInvoices' }" small elevation="2">
                  <v-icon class="mr-1" small>mdi-receipt-text-outline</v-icon>
                  <span>Invoices</span>
                </v-btn> -->
              </div>

              <!-- <template>
                <h5 class="mb-2 mt-8 text-center" style="width: 100%">Subscriptions</h5>
                <v-simple-table dense class="w-100 rounded-8">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Plan</th>
                        <th class="text-left">Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="$store.getters.getUser.subscriptions.length">
                        <tr v-for="(v, i) of $store.getters.getUser.subscriptions" :key="'sub' + i">
                          <td>{{ v.plan.product_name }}</td>
                          <td>{{ v.credit }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="2" class="text-center">Not subscribed to any plan</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template> -->
            </v-sheet>
          </v-col>

          <!-- property information column -->
          <v-col cols="12" md="8" class="mb-4">
            <v-sheet color="bsprimary2" elevation="3" rounded class="pa-4 mb-2" width="100%" height="100%">
              <template>
                <v-alert border="left" colored-border color="bssecondary2 accent-4" elevation="4" class="home-wedo-alert">
                  <div class="pa-2">
                    <h5 class="mb-2 text-center" style="width: 100%">News & Updates</h5>
                    <ul>
                      <li v-for="(v, i) of updates" :key="'ur' + i">{{ v }}</li>
                    </ul>
                  </div>
                </v-alert>
              </template>
              <template>
                <v-alert border="left" colored-border color="bssecondary2 accent-4" elevation="4" class="home-wedo-alert">
                  <div class="pa-2">
                    <h5 class="mb-2 text-center" style="width: 100%">What We Do</h5>
                    <p>At our REMAP.ai, we specialize in delivering top-notch solutions tailored to your specific needs. Here's how COMMSHUB can achieve that:</p>
                    <ul>
                      <li v-for="(v, i) of whatwedo" :key="'ur' + i">{{ v }}</li>
                    </ul>
                  </div>
                </v-alert>
              </template>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HomePage",
  data: () => {
    return {
      requestSent: false,

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      updates: [
        "Added Home page for updates, activity and most frequently used features.",
        "Added Single SMS Page to conveniently send direct messages though the application & keep record.",
        "Added Bulk SMS / SMS Campaign page to send SMS to a selection of contacts with variable fields",
        "Added intelligence in SMS campaigns to avoid SMS duplication & spamming pattern.",
        "Updated contacts database for faster load time.",
        "Updated application code for better & smoother SMS delivery.",
        "Optimized overall application performance for faster loading and smoother navigation.",
        "Implemented enhanced security measures to protect user data.",
      ],

      whatwedo: [
        "Connect with diverse professionals effortlessly.",
        "Unlock versatile SMS and email marketing services.",
        "Stay updated with a user-friendly Home Page.",
        "Send direct messages with ease and record them.",
        "Enhance marketing with Bulk SMS and SMS Campaigns.",
        "Smart SMS campaigns prevent duplication and spam.",
        "Faster contacts database for efficient access.",
        "Improved SMS delivery for prompt messages.",
        "Optimized performance for a better user experience.",
        "Robust data security for your protection.",
        "Tailor-made outputs to meet your company's needs.",
      ],
    };
  },
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {},
  async mounted() {
    console.log();
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        console.log("getuser: ", this.$store.getters.getUser);
        console.log("store", this.$store.user);
        await this.get_user_homepage_data();
      } catch (error) {}
    },

    async get_user_homepage_data() {
      try {
        return false;
        // checking for other requests
        if (this.table_loading) return;
        this.table_loading = true;
        await new Promise((r) => setTimeout(r, 200));
        // sending request for history
        let res = await this.axios.post("/api/content/gethistory", { options: this.options, page: "blog" });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);

        this.history = res.data.history;
        this.history_length = res.data.count;
      } catch (error) {
        console.log(error);
        this.table_loading = false;
        this.snackbarShow(true, "Error", "History could not be loaded: check your internet connection");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    format_date(date) {
      if (!date) return date;
      return date.replace("T", " ").replace(/\.[^.]*$/gi, "");
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.settings-div .v-btn {
  text-transform: initial;
  width: 146px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}
/* ::v-deep .v-input--selection-controls__ripple {

    margin: 0px !important;
} */
::v-deep .v-input__slider label {
  width: 75px;
}
.datatable-height {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-45 {
  width: 45% !important;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.settings-inner-div {
  /* border: 1px solid #21a6f6c2; */
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}

/* scroll styling */
#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

/* backgrounds */
::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}
::v-deep .simple-table {
  background-color: inherit !important;
}
::v-deep .simple-table th {
  background-color: black !important;
}

.column-content {
  flex: 1;
  align-self: stretch;
}

.home-wedo-alert p,
.home-wedo-alert ul {
  font-size: 13px;
  font-weight: 500;
}
</style>
