import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

// Views & components
import Home from "../views/dashboard/home/Home.vue";
// import HomeStats from "../views/dashboard/home/Stats.vue";
import DashboardMain from "../views/dashboard/Main.vue";
import UserInvoices from "../views/dashboard/home/UserInvoices.vue";
import UserSubscriptions from "../views/dashboard/home/UserSubscriptions.vue";

// Auth Views & Components
import Account from "../views/auth/Main.vue";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import ForgotPassword from "../views/auth/Forgot.vue";
import Logout from "../views/auth/Logout.vue";
import RegisterConfirmEmail from "../views/auth/RegisterConfirmEmail.vue";
import ForgotEmail from "../views/auth/ForgotEmail.vue";
import PendingVerification from "../views/auth/PendingVerification.vue";
import PendingAdminVerification from "../views/auth/PendingAdminVerification.vue";

import Users from "../views/dashboard/users/Main.vue";
import Notifications from "../views/dashboard/users/Notifications.vue";

// Services Views & Components
import Services from "../views/dashboard/services/Main.vue";
import ContactList from "../views/dashboard/services/burstsms/ContactList.vue";
import Contacts from "../views/dashboard/services/burstsms/Contacts.vue";
import SendSMS from "../views/dashboard/services/burstsms/SendSMS.vue";
import SendBulkSMS from "../views/dashboard/services/burstsms/SendBulkSMS.vue";

// campaigns & details
import Campaigns from "../views/dashboard/services/burstsms/Campaigns.vue";
import CampaignDetails from "../views/dashboard/services/burstsms/CampaignDetails.vue";
import SMSDetails from "../views/dashboard/services/burstsms/SMSDetails.vue";
import SMSInbox from "../views/dashboard/services/burstsms/Inbox.vue";

// mailchimp views & components
import MailchimpContacts from "../views/dashboard/services/mailchimp/Contacts.vue";
import MailchimpSendEmail from "../views/dashboard/services/mailchimp/SendEmail.vue";
import MailchimpCampaigns from "../views/dashboard/services/mailchimp/Campaigns.vue";
import EmailCampaignComing from "../views/dashboard/services/mailchimp/EmailCampaignComing.vue";

// Users Views & Components
import ChangeSettings from "../views/dashboard/users/ChangeSettings.vue";
import ChangeUserSettings from "../views/dashboard/users/ChangeUserSettings.vue";
import ViewActivity from "../views/dashboard/users/ViewActivity.vue";

// email routes; sendgrid
import SGSendBulkEmail from "../views/dashboard/services/sendgrid/EmailBulkTrans";
import SGViewBulkEmail from "../views/dashboard/services/sendgrid/EmailBulkTransView";
import SGViewBulkEmailDetails from "../views/dashboard/services/sendgrid/EmailCampaignTransDetail";


// documentation components
// import Documentation from "../views/dashboard/docs/Documentation.vue";
// import Reference from "../views/dashboard/docs/Reference.vue";

import TestComponent from "../views/dashboard/TestComponent.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    children: [
      { path: "login", name: "Login", component: Login },
      { path: "register", name: "Register", component: Register },
      { path: "forgotpassword", name: "Forgot Password", component: ForgotPassword },
      { path: "logout", name: "Logout", component: Logout },
      { path: "registerconfirm", name: "RegisterConfirmEmail", component: RegisterConfirmEmail },
      { path: "forgotemail", name: "ForgotEmail", component: ForgotEmail },
      { path: "pendingverification", name: "PendingVerification", component: PendingVerification },
      { path: "PendingAdminVerification", name: "PendingAdminVerification", component: PendingAdminVerification },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardMain,
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: { page: "Home" },
      },
      {
        path: "subscriptions",
        name: "UserSubscriptions",
        component: UserSubscriptions,
        meta: { page: "Subscriptions" },
      },
      {
        path: "invoices",
        name: "UserInvoices",
        component: UserInvoices,
        meta: { page: "Invoices" },
      },
      {
        path: "testcomponent",
        name: "TestComponent",
        component: TestComponent,
        meta: { page: "Test Component (Do not use it)" },
      },
      {
        path: "services",
        name: "Services",
        component: Services,
        children: [
          { path: "burstsms/contactlist", name: "ContactList", component: ContactList, meta: { page: "Agent Contact Lists" } },
          { path: "burstsms/contacts", name: "Contacts", component: Contacts, meta: { page: "Agent Contacts" } },
          { path: "burstsms/sendsms", name: "SendSMS", component: SendSMS, meta: { page: "Quick SMS" } },
          { path: "burstsms/sendbulksms", name: "SendBulkSMS", component: SendBulkSMS, meta: { page: "Send Bulk SMS" } },
          { path: "burstsms/details", name: "SMSDetails", component: SMSDetails, meta: { page: "Outbox" } },

          { path: "mailchimp/campaigns", name: "MailchimpCampaigns", component: MailchimpCampaigns, meta: { page: "MailChimp - Campaigns" } },
          { path: "mailchimp/contacts", name: "MailchimpContacts", component: MailchimpContacts, meta: { page: "MailChimp - Contacts" } },
          { path: "mailchimp/sendemail", name: "MailchimpSendEmail", component: MailchimpSendEmail, meta: { page: "MailChimp - Send Email Campaign" } },

          // sendgrid routes
          // { path: "mailchimp/comingsoon", name: "EmailCampaignComing", component: EmailCampaignComing, meta: { page: "Email" } },
          { path: "email/sendbulktransactional", name: "SGSendBulkEmail", component: SGSendBulkEmail, meta: { page: "Send Email Campaign" } },
          { path: "email/viewbulktransactional", name: "SGViewBulkEmail", component: SGViewBulkEmail, meta: { page: "View Email Campaigns" } },
          { path: "email/viewbulktransactionaldetails", name: "SGViewBulkEmailDetails", component: SGViewBulkEmailDetails, meta: { page: "Email Campaign Details" } },
          

          { path: "burstsms/campaigns", name: "Campaigns", component: Campaigns, meta: { page: "SMS Campaigns" } },
          { path: "burstsms/campaigndetails", name: "CampaignDetails", component: CampaignDetails, meta: { page: "SMS Details" } },
          { path: "smsinbox", name: "SMSInbox", component: SMSInbox, meta: { page: "Inbox" } },
        ],
      },
      {
        path: "users",
        name: "Users",
        component: Users,
        children: [
          { path: "manageaccount/changesettings", name: "ChangeSettings", component: ChangeSettings, meta: { page: "Edit Account" } },
          { path: "manageusers/changeusersettings", name: "ChangeUserSettings", component: ChangeUserSettings, meta: { page: "Users" } },
          { path: "manageusers/viewactivity", name: "ViewActivity", component: ViewActivity, meta: { page: "User Activity Logs" } },
          { path: "notifications", name: "Notifications", component: Notifications, meta: { page: "Notifications" } },
        ],
      },
      // {
      //   path: "settings",
      //   name: "Settings",
      //   component: Settings,
      // },
      // {
      //   path: "documentation",
      //   name: "Documentation",
      //   component: Documentation,
      //   meta: { page: "User Manual" },
      // },
      // {
      //   path: "reference",
      //   name: "Reference",
      //   component: Reference,
      //   meta: { page: "Technical Documentation" },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("setHeading", to.meta.page);
  next();
});

export default router;
