<template>
  <v-card max-width="500" height="93vh" flat class="rounded-0 submenu">
    <h4 class="text-uppercase grey--text text-center py-5">{{menuTitle}}</h4>
    <v-list>
      <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="text-uppercase py-1 font-weight-bold"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="child in item.items" :key="child.title" router :to="child.route">
          <v-list-item-content>
            <v-list-item-title v-text="child.title" class="text-capitalize grey--text py-1 font-weight-medium"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "SubMenu",
  props:['items', 'menuTitle'],
  data: () => ({
    
  }),
};
</script>

<style scoped>
.submenu{
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.v-list-group__header.v-list-item,
.v-list-item {
  min-height: 20px !important;
}
.v-list-item__content {
    padding: 5px 0;
}
.v-list-item__title {
  font-size: 0.8rem;
  line-height: 1rem;
}
.v-list-group__items > .v-list-item {
    padding-left: 50px !important;
}

::v-deep .v-list-item__icon.v-list-group__header__prepend-icon {
    margin-right: 10px !important;
}
</style>
