<template>
  <!-- View Layout -->
  <v-row class="d-flex flex-row flex-wrap justify-space-between align-start ma-0 pa-0" align="stretch">
    <!-- View header -->

    <v-overlay :value="loading" style="z-index: 20001">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar v-model="showSnackbar" timeout="-1" :vertical="true" app top right style="opacity: 1; z-index: 2005" content-class="main-snackbar-content" elevation="3" max-width="150">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <!-- Contacts Column -->
    <v-col cols="12" md="8" class="ma-0 pa-0 d-flex align-start justify-start">
      <section-datatable :headers="headers" @datatable-selection-updated="(v) => (selected = v)" ref="ref_datatable_component"></section-datatable>
    </v-col>

    <!-- Message column -->
    <v-col cols="12" md="4" class="d-flex justify-center pa-0 ma-0 send-div my-2 my-md-0">
      <!-- <v-card class="px-5 mx-0 rounded-0" style=" width: 100%"> -->
      <v-sheet color="white" elevation="3" rounded class="d-flex align-start justify-center mr-2 px-5" style="width: 100%">
        <div style="width: 100%">
          <!-- show number of selected contacts to send message -->
          <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0 py-0">
            <v-text-field :value="`${selected.length} contacts selected`" disabled class="rounded-2 my-3" hide-details dense solo></v-text-field>
          </v-col>

          <!-- field for campaign title -->
          <v-text-field v-model="email_options.campaign_title" label="Campaign Title" class="rounded-2 my-3" hide-details dense solo></v-text-field>

          <!-- field to select excluded campaigns -->
          <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0 py-0">
            <v-autocomplete
              multiple
              class="rounded-2 my-3"
              v-model="email_options.campaigns_excluded"
              :items="campaigns"
              item-text="title"
              item-value="id"
              label="Campaigns to exclude"
              :return-object="false"
              hide-details
              dense
              solo
              small-chips
              deletable-chips
            ></v-autocomplete>
          </v-col>

          <!-- field for send_from number -->
          <v-select v-model="email_options.template" :items="templates" item-value="id" label="Select Template" class="my-3 rounded-2" hide-details dense solo></v-select>
          <v-select v-model="email_options.from" :items="available_emails" label="Select Sender Email" class="my-3 rounded-2" hide-details dense solo></v-select>

          <v-text-field v-model="email_options.subject" label="Email Subject" class="rounded-2 my-3" hide-details dense solo></v-text-field>
          <div class="mb-2 x-small">
            <v-chip v-for="(shortcode, index) in shortcodes" :key="index" @click="addShortcode(shortcode.symbol)" color="primary" small chip label outlined class="mr-1 rounded-2">
              {{ shortcode.symbol }}
            </v-chip>
          </div>
          <v-textarea label="Content" v-model="email_options.content" required hide-details dense solo class="my-3 rounded-2" rows="6"></v-textarea>
          <v-checkbox v-model="checkbox" :label="`Confirm sending to ${selected.length} contacts?`" required hide-details dense solo class="my-3 rounded-2"></v-checkbox>

          <v-col cols="12" class="d-flex flex-wrap align-baseline justify-end py-0 my-0 mx-0 px-0">
            <v-btn color="white" class="mx-1 my-1" small elevation="2" @click="clear_selection">
              <v-icon class="mr-1" small>mdi-selection-off</v-icon>
              Clear Selected
            </v-btn>
            <v-btn color="white" class="mx-1 my-1" small elevation="2" @click="reset">
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              Reset
            </v-btn>

            <v-btn color="primary" class="mx-0 my-1" small elevation="2" :disabled="selected.length < 1 || this.requestSent" @click="send_campaign">
              <v-icon class="mr-1" small>mdi-send</v-icon>
              Send
            </v-btn>
          </v-col>
        </div>
      </v-sheet>
      <!-- </v-card> -->
    </v-col>
  </v-row>
</template>

<script>
import SectionDatatable from "./SectionDatatable.vue";
export default {
  name: "BulkSMS",
  components: {
    SectionDatatable,
  },
  data: () => ({
    requestSent: false,

    // snackbar fields
    showSnackbar: false,
    snackbarText: "Message Progress...",
    snackbarText2: "",

    checkbox: false,

    // Table selection
    selected: [],

    // email object
    email_options: {
      from: "",
      to: [], // list of contact ids
      subject: "",
      content: "", // content body with shortcodes / substitutions
      campaign_title: "",
      campaigns_excluded: [],
      template: null,
    },
    campaigns: ["campaign1", "campaign2"],
    available_emails: ["hamza@remap.ai"],
    templates: [],
    shortcodes: [],

    // child component table headers
    headers: [
      { text: "Name", align: "start", sortable: true, value: "name" },
      { text: "Role", value: "role" },
      { text: "Type", value: "type" },
      { text: "Category", value: "category" },
      { text: "Email", value: "email" },
      { text: "Agency", value: "agency" },
      { text: "Details", value: "data-table-expand" },
    ],
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {},
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.get_email_data();
    },

    // validating and sending SMS
    async send_campaign() {
      try {
        await new Promise((r) => setTimeout(r, 200));

        // email_options = {
        //   from: "",
        //   to: [], // list of contact ids
        //   subject: "",
        //   content: "", // content body with shortcodes / substitutions
        //   campaign_title: "",
        //   campaigns_excluded: [],
        // };

        // validating form data
        if (!this.selected.length) throw new Error("Select contacts from table to send email to");
        if (!this.email_options.campaign_title) throw new Error("Campaign title must be a valid value");
        if (!this.email_options.from) throw new Error("Selet an email to send campaign from");
        if (!this.email_options.subject) throw new Error("Email subject can not be empty");
        if (!this.email_options.content) throw new Error("Email body can not be empty");
        if (!this.checkbox) throw new Error("Confirm campaign sending by checking the checkbox");

        const options = Object.assign({}, this.email_options);
        options.to = this.selected.map((contact) => contact.id);
        console.log(options);

        this.requestSent = true;

        // sending request for sms
        const res = await this.axios.post("/api/email/sendcampaigntransactional", { options });
        console.log(res.data);
        this.requestSent = false;
        if (!res.data.success) throw new Error(res.data.message);

        this.snackbarShow(true, "Info", res.data.message, true);
        this.get_email_data();
        // this.reset();
        // this.clear_selection();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message || "Messags could not be sent: check your internet connection");
      }
    },
    // v-form validation reset functions
    reset() {
      this.selected = [];
      this.checkbox = false;
      for (let k of Object.keys(this.email_options)) {
        if (Array.isArray(this.email_options[k])) this.email_options[k] = [];
        else if (typeof this.email_options[k] == "string") this.email_options[k] = "";
      }
    },
    clear_selection() {
      this.$refs.ref_datatable_component.clear_selection();
    },
    // adding predefined shortcodes to the text area
    addShortcode(symbol) {
      this.email_options.content += symbol + " ";
    },
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    async get_email_data() {
      try {
        let res = await this.axios.get("/api/email/getallcampaignstransactional");
        if (!res.data.success) throw new Error(res.data.message);
        this.campaigns = res.data.campaigns;
        this.templates = res.data.templates;
        this.available_emails = res.data.available_emails;
        this.shortcodes = res.data.shortcodes;
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message || "Campaigns could not be loaded: check your connection");
      }
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
.send-div .v-btn {
  text-transform: initial;
  width: 120px;
  float: right;
}
.send-div .v-btn .v-icon {
  width: 20px;
}
</style>
