<template>
  <v-row>
    <!-- Tabs Col Start -->
    <v-col cols="12" sm="12" md="12">
      <v-card class="mx-2">
        <v-overlay :value="loading" :z-index="20004">
          <v-snackbar v-model="loading" timeout="-1" centered>
            <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
            <span class="py-2 px-2">Processing Request...</span>
          </v-snackbar>
        </v-overlay>

        <v-snackbar
          :value="showSnackbar"
          timeout="-1"
          :vertical="true"
          :multi-line="true"
          app
          bottom
          right
          style="opacity: 0.9; z-index: 2005"
          content-class="main-snackbar-content d-flex flex-column align-start justify-start"
        >
          <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
          <div class="py-1 text-capitalize" style="font-size: 13px">
            <template v-if="typeof snackbarText2 == 'string'">
              <div>{{ snackbarText2 }}</div>
            </template>
            <template v-else>
              <template v-for="(value, key) in snackbarText2">
                <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</div>
              </template>
            </template>
          </div>
          <template v-slot:action="">
            <v-btn small color="blue" text @click="showSnackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>

        <v-container fluid>
          <!-- FILTERS ROW START-->
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-select
                :items="lists"
                item-text="name"
                item-value="id"
                class="rounded-0"
                label="Contacts by lists / audiances"
                :return-object="false"
                filled
                v-model="selected_list"
                @change="getContacts()"
                dense
                solo
                clearable
                hide-details
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="4" class="d-flex justify-end">
              <v-btn color="primary" dark text outlined class="font-weight-black" @click="initialize()"><v-icon>mdi-refresh</v-icon></v-btn>
              <v-btn color="primary" dark text outlined class="font-weight-black" @click="showInfoAlert()"><v-icon>mdi-information</v-icon></v-btn>
              <v-btn color="primary" dark text outlined class="font-weight-black" @click="addContact()"><v-icon>mdi-account-plus</v-icon></v-btn>
            </v-col>
            <!-- Add / Edit Modal -->
          </v-row>

          <template>
            <v-row class="align-baseline" transition="slide-y-transition">
              <v-col cols="12" xs="12" sm="12" md="4">
                <v-text-field
                  class="rounded-0"
                  label="Search all lists by Email, Firstname or lastname (complete)"
                  filled
                  v-model="search"
                  dense
                  solo
                  clearable
                  hide-details
                  @keyup.enter="searchContacts()"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <!-- FILTERS ROW END-->
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="contacts"
                item-key="id"
                :options.sync="options"
                :server-items-length="contactsCount"
                sort-by="address"
                class="mx-0 my-2 px-0 fill-height"
                outlined
                :single-select="singleSelect"
                show-expand
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                fixed-header
                dense
                flat
                :footer-props="{
                  'items-per-page-options': [50, 100, 250, 500],
                }"
                :hide-default-footer="search_active"
              >
                <!-- expansion template: start -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-simple-table dense class="ma-5" flat>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="(value, key) in item" :key="key">
                            <template v-if="!excludeShow.includes(key)">
                              <td style="width: 200px" class="font-weight-bold text-capitalize">
                                {{ key | removeUnderscore }}
                              </td>
                              <td v-if="type(value) != 'object'">
                                {{ value }}
                              </td>
                              <td v-else>
                                <template v-for="(ovalue, okey) in value">
                                  <div v-if="!excludeShow.includes(okey)" :key="okey">
                                    <span>
                                      <b class="text-capitalize">{{ okey | removeUnderscore }}:</b>
                                    </span>
                                    <span>{{ ovalue }}</span>
                                  </div>
                                </template>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-simple-table dense class="ma-5" flat>
                      <template v-slot:default>
                        <tbody>
                          <tr @click="getContactMarkets(item)">
                            <template>
                              <td style="width: 200px; cursor:pointer" class="font-weight-bold text-capitalize">
                                <button>Markets</button>
                              </td>
                              <td style="cursor:pointer" v-if="item.markets && item.markets.length">
                                <div v-for="(value, index) in item.markets" :key="index">{{ value }}</div>
                              </td>
                              <td style="cursor:pointer" v-else>
                                <span v-if="item.merge_fields.CID">Click to load contact markets</span>
                                <span v-else>No markets found for this contact, it does not exist in Remap Database</span>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
                <!-- expansion template: end -->

                <!-- Actions slot -->
                <template v-slot:[`item.actions`]="{ item }">
                  <!-- Action Menu -->
                  <v-menu bottom offset-x left dense>
                    <!-- Options Menu Button -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="" color="blue" v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                    </template>
                    <!-- Option Menu List -->
                    <v-list class="rounded-0" dense>
                      <v-list-item @click="editContact(item)">
                        <v-list-item-content>
                          <v-list-item-title class="item-actions-menu">
                            <span>Edit Contact</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item @click="deleteContact(item.email_address)">
                        <v-list-item-content>
                          <v-list-item-title class="item-actions-menu">
                            <span>Delete Contact</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item @click="changeContactStatus(item.email_address, item.status)">
                        <v-list-item-content>
                          <v-list-item-title class="item-actions-menu">
                            <span :color="item.status == 'subscribed' ? 'red' : 'blue'">{{ item.status === "subscribed" ? "Unsubscribe" : "Subscribe" }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item :disabled="item.status === 'subscribed'" @click="changeContactStatus(item.email_address, 'pending')">
                        <v-list-item-content>
                          <v-list-item-title class="item-actions-menu">
                            <span>Resend Subscription Email</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <template v-slot:[`item.list_id`]="{ item }">
                  <span>{{ getlist(item.list_id) }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <!-- Tabs Col End -->
    <template>
      <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
        <v-card class="rounded-0">
          <v-toolbar dark color="#1d4354" class="dialogNavbar" height="49">
            <v-toolbar-title>Add Contact</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="saveDialog()">Save</v-btn>
              <v-btn icon dark @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-row class="px-5" style="max-width: 100%">
            <v-spacer></v-spacer>
            <v-col cols="6" class="px-5 form-content">
              <!-- basic fields template -->
              <template>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <div class="text-center heading my-5">
                    Contact Information
                  </div>
                  <v-text-field
                    :value="dialogContact.email_address"
                    @change="(v) => (dialogContact.email_address = v)"
                    :rules="emailRules"
                    label="Email"
                    required
                    class="rounded-0"
                    solo
                  ></v-text-field>
                  <v-text-field
                    :value="dialogContact.merge_fields.FNAME"
                    @change="(v) => (dialogContact.merge_fields.FNAME = v)"
                    :rules="nameRules"
                    label="Firstname"
                    required
                    class="rounded-0"
                    solo
                  ></v-text-field>
                  <v-text-field
                    :value="dialogContact.merge_fields.LNAME"
                    @change="(v) => (dialogContact.merge_fields.LNAME = v)"
                    :rules="nameRules"
                    label="Lastname"
                    required
                    class="rounded-0"
                    solo
                  ></v-text-field>
                  <v-text-field
                    :value="dialogContact.merge_fields.PHONE"
                    @change="(v) => (dialogContact.merge_fields.PHONE = v)"
                    label="Phone Number (optional)"
                    required
                    class="rounded-0"
                    solo
                  ></v-text-field>
                  <v-combobox
                    :value="dialogContact.markets"
                    @change="(v) => (dialogContact.markets = v)"
                    :items="$store.state.listings.filter_items.suburb"
                    :item-text="(v) => `${v.suburb}, ${v.state}, ${v.postcode}`"
                    multiple
                    label="Markets"
                    class="rounded-0"
                    autocomplete="false"
                    :return-object="false"
                    dense
                    required
                    solo
                    hide-details
                    small-chips
                    deletable-chips
                  ></v-combobox>
                </v-form>
              </template>
            </v-col>

            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </v-dialog>
    </template>
  </v-row>
</template>

<script>
export default {
  name: "MailChimpManageContacts",
  data: () => ({
    // dialog options
    showDialog: false,
    valid: true,
    nameRules: [(v) => !!v || "Required Field", (v) => (v && v.length <= 20) || "Must be less than 20 characters"],
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],

    // dialog fields
    new_contact: false,
    dialogContact: {
      id: "",
      markets: [],
      email_address: "",
      merge_fields: {
        FNAME: "",
        LNAME: "",
        PHONE: "",
        CID: "",
      },
    },

    lists: [],
    selectedList: "",
    activeList: "agents",
    contacts: [],
    contactsCount: 0,

    // table data
    headers: [
      { text: "First Name", value: "merge_fields.FNAME", sortable: false },
      { text: "Last Name", value: "merge_fields.LNAME", sortable: false },
      { text: "Email", value: "email_address", sortable: false },
      { text: "List", value: "list_id", sortable: false },
      { text: "Details", value: "data-table-expand", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datatableId: 0,
    options: {
      page: 1,
      itemsPerPage: 250,
      itemsLength: 0,
      sortBy: ["timestamp_opt"],
      sortDesc: [false],
    },
    defaultOptions: {
      page: 1,
      itemsPerPage: 250,
      itemsLength: 0,
      sortBy: ["timestamp_opt"],
      sortDesc: [false],
    },

    selected: [],
    search: "",
    search_active: false,

    // listing's detail view attributes
    excludeShow: ["id", "ADDRESS", "BIRTHDAY", "_links", "markets"],

    // table attributes
    singleSelect: false,
    singleExpand: true,
    expanded: [],
    errorStatus: "",
    requestSent: false,

    // alerts
    snackbar: true,
    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    //list search fields
    selected_list: "",
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    options() {
      this.search_active ? this.searchContacts() : this.getContacts();
    },
    requestSent(newval) {
      if (newval) this.setSnackbar(false, "", "");
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.options.page = 1;
      this.options.itemsPerPage = this.defaultOptions.itemsPerPage;
      this.search = "";
      this.selected_list = "";
      await this.getLists();
      await this.getContacts();
    },
    async getLists() {
      try {
        this.requestSent = true;
        let res = await this.axios.get("/api/mailchimp/getlists");
        this.requestSent = false;

        if (!res.data.success) {
          return this.setSnackbar(true, "", res.data.message);
        }
        this.lists = res.data.lists;
        console.log(this.lists);
      } catch (error) {
        this.requestSent = false;
        this.setSnackbar(true, "Error", "Unable to get mailchimp lists / audiances - Kindly check your internet connection");
        console.log(error);
      }
    },
    async getContacts() {
      try {
        this.requestSent = true;
        this.search_active = false;
        let res = await this.axios.post("/api/mailchimp/getcontacts", {
          options: this.options,
          list_id: this.selected_list,
        });
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "Alert", res.data.message);
        }
        this.contacts = res.data.contacts;
        this.contactsCount = res.data.count;
        this.contacts.forEach((c) => {
          if (c.merge_fields.MARKETS && typeof c.merge_fields.MARKETS == "string") {
            try {
              c.merge_fields.MARKETS = JSON.parse(c.merge_fields.MARKETS);
            } catch (e) {
              c.merge_fields.MARKETS = [];
            }
          }
        });
      } catch (error) {
        console.log("Unable to get list contacts - Kindly check your internet connection");
        console.log(error);
      }
    },
    async searchContacts() {
      try {
        if (!this.search || this.search.length < 3 || this.requestSent) return;
        this.requestSent = true;
        this.search_active = true;
        let res = await this.axios.post("/api/mailchimp/searchcontacts", {
          search: this.search,
        });
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "Alert", res.data.message);
        }
        this.contacts = res.data.contacts;
        this.contactsCount = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log("Unable to search contacts - Kindly check your internet connection");
        console.log(error);
      }
    },
    async getContactMarkets(item, return_value) {
      try {
        let id = item.merge_fields.CID;
        if (!id) return;
        this.requestSent = true;
        let res = await this.axios.post("/api/mailchimp/getcontactmarkets", { id });
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "", res.data.message);
        }

        if (return_value) {
          return res.data.markets;
        } else {
          item.markets = res.data.markets;
        }
      } catch (error) {
        this.requestSent = false;
        this.setSnackbar(true, "Error", "Unable to get mailchimp lists / audiances - Kindly check your internet connection");
        console.log(error);
      }
    },
    // opening dialog for adding contact
    async addContact() {
      this.setDefaultDialogValues();
      this.showDialog = true;
    },
    // opening dialog for editing contact
    async editContact(contact) {
      this.dialogContact = Object.assign({}, contact);
      this.dialogContact.old_email = contact.email_address;
      this.new_contact = false;
      this.dialogContact.markets = await this.getContactMarkets(contact, true);
      this.showDialog = true;
    },
    async deleteContact(email) {
      try {
        if (!email) return;
        this.requestSent = true;
        let res = await this.axios.post("/api/mailchimp/deletecontact", {
          email,
        });
        this.requestSent = false;
        this.$store.dispatch("setSnackBar", res.data.message);
        if (res.data.success) await this.getContacts();
      } catch (error) {
        this.requestSent = false;
        console.log("Unable to send delete contact request - Kindly check your internet connection");
        console.log(error);
      }
    },
    async changeContactStatus(email, status) {
      try {
        if (!email || !status) return;
        this.requestSent = true;
        let res = await this.axios.post("/api/mailchimp/changestatus", {
          email,
          status,
        });
        this.requestSent = false;
        res.data.error
          ? this.$store.dispatch("setSnackBar", `${res.data.message}  (${res.data.error})`)
          : this.$store.dispatch("setSnackBar", `${res.data.message}`);
        if (res.data.success) await this.getContacts();
      } catch (error) {
        this.requestSent = false;
        console.log("Unable to send contact status request - Kindly check your internet connection");
        console.log(error);
      }
    },
    async saveDialog() {
      try {
        // validating form
        if (!this.$refs.form.validate()) return;

        // initializing local variables
        let contact = Object.assign({}, this.dialogContact);
        let new_contact = this.new_contact;

        // validating mobile / phone number
        if (contact.merge_fields.PHONE && contact.merge_fields.PHONE.length < 8) {
          return this.setSnackbar(true, "Alert", "Invalid phone number");
        }

        // validating markets
        const pattern = /[\w\s]+,\s[\w]+,\s\d{4}/;
        for (let market of contact.markets) {
          if (!pattern.test(market)) {
            return this.setSnackbar(true, "Alert", `incorrect market format '${market}' `);
          }
        }
        

        // sending request
        this.requestSent = true;
        let res = await this.axios.post("/api/mailchimp/createcontact", {
          contact,
          new_contact,
        });
        this.requestSent = false;
        console.log(res.data)
        // handling response
        if (!res.data.success) this.setSnackbar("setSnackBar", `${res.data.message}  (${res.data.error})`);
        else this.setSnackbar("setSnackBar", `${res.data.message}`);

        // handling success case
        if (res.data.success) {
          // if new contact saved
          if (new_contact) {
            this.contacts.unshift(res.data.contact);
            this.contactsCount++;
          }
          // if existing contact updated
          if (!new_contact) {
            let contact_index = this.contacts.findIndex((c) => c.id == res.data.contact.id);
            this.contacts.splice(contact_index, 1, res.data.contact);
          }
        }
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "Unable to send create / update contact request - Kindly check your internet connection");
      }
    },
    closeDialog() {
      // resetting alidation before form is destroyed
      this.showDialog = false;
      this.$refs.form.resetValidation();
      this.setDefaultDialogValues();
    },

    setSnackbar(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    type(value) {
      return typeof value;
    },
    setDefaultDialogValues() {
      let defaultvalue = {
        id: "",
        markets: [],
        email_address: "",
        merge_fields: {
          FNAME: "",
          LNAME: "",
          PHONE: "",
          CID: "",
        },
      };
      this.dialogContact = defaultvalue;
      this.new_contact = true;
    },
    getlist(listid) {
      return this.lists.length ? this.lists.filter((l) => l.id == listid)[0].name : "";
    },
    showInfoAlert() {
      this.setSnackbar(
        true,
        "Information",
        `This module contains Contacts/Members for MailChimp Lists/Audiances, 
        The intention is to provides single place to add, edit & manage members in both MailChimp & Remap.`
      );
    },
  },
  filters: {
    removeUnderscore(str) {
      return typeof str == "string" ? str.replace(/_/g, " ") : "";
    },
    formatDate: function(date) {
      try {
        let m = moment(date);
        return `${m.format("YYYY MMM DD HH:mm:ss")} (${m.fromNow()})`;
      } catch (e) {
        return date;
      }
    },
    formatDatetime(datetime) {
      try {
        return moment(datetime).format("DD MMM YY (HH:mma) ");
      } catch (e) {
        return datetime;
      }
    },
  },
};
</script>

<style scoped>
.table-link {
  text-decoration: none;
}
::v-deep .v-select.v-input--dense .v-chip {
  margin-top: 2px;
  margin-bottom: 2px;
}
::v-deep .v-select__selections {
  padding: 5px 0px;
}
::v-deep .v-snack__content {
  display: flex;
  align-items: center;
}
::v-deep .gm-style-iw button {
  display: none !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .item-actions-menu {
  display: flex;
  justify-content: flex-start;
}
::v-deep .item-actions-menu i {
  margin-right: 8px;
}
.smaller-font {
  font-size: 10px;
}
::v-deep .v-snack__wrapper {
  max-width: 500px;
}
</style>
