<template>
  <v-row justify="center">
    <!-- loading overlay -->
    <v-overlay :value="loading" style="z-index: 20001">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar @input="update_snackbar" :value="showSnackbar" timeout="1000" :vertical="true" app bottom right style="opacity: 1; z-index: 2005" content-class="main-snackbar-content" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template>
          <div>{{ snackbarText2 }}</div>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- main dialog box -->
    <v-dialog :value="show" persistent max-width="500" hide-overlay class="rounded-0 pa-2">
      <v-card>
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <h4>SEND SMS</h4>
          <v-spacer></v-spacer>
          <div>
            <v-btn small icon dark @click="$emit('close-sms-dialog', false)"><v-icon small>mdi-close</v-icon></v-btn>
          </div>
        </v-toolbar>

        <div class="pa-3">
          <v-text-field v-model="mobile" :counter="18" label="Mobile" required hide-details dense solo class="rounded-2 my-3"></v-text-field>
          <v-textarea label="Message" v-model="content" :counter="160" required class="rounded-2" hide-details dense solo></v-textarea>

          <v-col cols="12" class="d-flex justify-end py-0 my-0 mt-2 mx-0 px-0 profile-div">
            <v-btn color="primary" class="mx-0 my-1" small elevation="2" :disabled="!mobile || !content || request_sent" @click="send_sms"
              ><v-icon class="mr-1" small>mdi-content-save</v-icon>Send</v-btn
            >
          </v-col>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show", "mobile_number", "contact_id"],
  data: () => {
    return {
      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",

      // dialogue fields
      mobile: "",
      content: "",
      request_sent: false,
    };
  },

  watch: {
    mobile_number(newval) {
      this.mobile = newval;
    },
  },
  computed: {
    loading() {
      return this.request_sent;
    },
  },
  methods: {
    async send_sms() {
      try {
        if (this.request_sent) return;
        this.request_sent = true;
        await new Promise((r) => setTimeout(r, 200));

        const mobile = this.mobile;
        const message = this.content;
        const contact_id = this.contact_id;

        if (!mobile) throw new Error("Mobile number is required to send an SMS");
        if (!message) throw new Error("SMS content number is required to send an SMS");

        const sms = {
          contact: contact_id,
          countrycode: "au",
          from: "+61480008208",
          message,
          mobile,
          newNumber: true,
        };

        // console.log(sms);
        // this.request_sent = false;
        // return;

        // sending request for contacts
        let res = await this.axios.post("/api/bsms/sendsms", sms);
        this.request_sent = false;
        console.log(res.data);

        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Success", res.data.message || "Message sent successfully");
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message || "SMS could not be sent - connection error");
      }
    },
    // show/unshow snakbar => mostly errors
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    update_snackbar(v) {
      this.showSnackbar = v;
    },
  },
};
</script>

<style scoped>
.read-mode-toolbar {
  height: 50px;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 400;
  font-size: 16px !important;
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}
.pac-target-input,
.pac-target-input:focus-visible {
  width: 100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 6px 12px;
  border-radius: 4px;
  border: none !important;
  background-color: #ffffff;

  font-weight: 500;
  font-size: 13px !important;
  margin-bottom: 10px;
}

.small-font,
.small-font * {
  font-weight: 500;
  font-size: 13px !important;
}

.map-box {
  width: 100%;
  height: 400px;
  border-radius: 4px;
}
.pac-container::after {
  display: none !important;
  font-size: 40px;
}
::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* --------------------------- */
* {
  letter-spacing: normal;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}

.profile-div .v-btn {
  text-transform: initial;
  width: 146px;
  float: right;
}
.profile-div .v-btn .v-icon {
  width: 20px;
}
</style>
