<template>
  <!-- View Layout -->
  <v-row class="d-flex flex-row flex-wrap justify-space-between align-start ma-0 pa-0" align="stretch">
    <!-- View header -->

    <v-overlay :value="loading" style="z-index: 20001">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar :value="showSnackbar" timeout="-1" :vertical="true" app top right style="opacity: 1; z-index: 2005" content-class="main-snackbar-content" elevation="3" max-width="150">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <template v-if="typeof snackbarText2 == 'string'">
          <div>{{ snackbarText2 }}</div>
        </template>
        <template v-else>
          <template v-for="(value, key) in snackbarText2">
            <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</div>
          </template>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <!-- Contacts Column -->
    <!-- Contacts Column -->
    <v-col cols="12" md="8" class="ma-0 pa-0 pr-2 d-flex align-start justify-start">
      <section-datatable :headers="headers" @datatable-selection-updated="(v) => (selected = v)" ref="ref_datatable_component"></section-datatable>
    </v-col>

    <!-- Message column -->
    <v-col cols="12" md="4" class="d-flex justify-center pa-0 ma-0 send-div my-2 my-md-0">
      <!-- <v-card class="px-5 mx-0 rounded-0" style=" width: 100%"> -->
      <v-sheet color="white" elevation="3" rounded class="d-flex align-start justify-center mr-2 px-5" style="width: 100%">
        <v-form ref="form" v-model="valid" style="width: 100%">
          <!-- show number of selected contacts to send message -->
          <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0 py-0">
            <v-text-field :value="selectedFilter(selected.length)" disabled class="rounded-2 my-3" hide-details dense solo></v-text-field>
          </v-col>

          <!-- field to select excluded campaigns -->
          <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0 py-0">
            <v-autocomplete
              multiple
              class="rounded-2 text-capitalize"
              v-model="excludeCampaignFilter"
              :items="$store.getters.getSMSTitles"
              label="Exclude Campaigns"
              :return-object="false"
              hide-details
              dense
              solo
              autocomplete="false"
              small-chips
              deletable-chips
            ></v-autocomplete>
          </v-col>

          <!-- field for campaign title -->
          <v-combobox v-model="sms.title" :items="$store.getters.getSMSTitles" label="Campaign Title" :rules="titleRules" class="my-3 rounded-2" hide-details dense solo></v-combobox>

          <!-- field for send_from number -->
          <!-- <v-select v-model="sms.from" :items="$store.state.sms.smsFrom" label="Send From" class="my-3 rounded-2" hide-details dense solo></v-select> -->
          <v-select v-model="sms.from" :items="['+61480008208']" label="Send From" class="my-3 rounded-2" hide-details dense solo></v-select>

          <div class="mb-2 x-small">
            <v-chip v-for="(shortcode, index) in $store.getters.getShortcodes" :key="index" @click="addShortcode(shortcode.symbol)" color="primary" small chip label outlined class="mr-1 rounded-2">
              {{ shortcode.symbol }}
            </v-chip>
          </div>
          <v-textarea label="Message" v-model="sms.message" :rules="messageRules" :counter="160" required hide-details dense solo class="my-3 rounded-2" rows="8"></v-textarea>
          <v-checkbox v-model="checkbox" :rules="checkboxRules" :label="messageConfirm(selected.length)" required hide-details dense solo class="my-3 rounded-2"></v-checkbox>

          <!-- <v-flex class="d-flex justify-end my-3"> -->
          <!-- <v-btn color="green" outlined small text class="ml-1 px-2 rounded-0" @click="(selected = []), (excludeCampaignFilter = [])"> Clear Selected </v-btn> -->
          <!-- <v-btn color="grey" outlined small text class="ml-1 px-2 rounded-0" @click="reset"> Cancel </v-btn> -->
          <!-- <v-btn :disabled="!valid || selected.length < 1 || messageRequestSent" color="red" outlined small text class="ml-1 px-2 rounded-0" @click="validate" align-end> Send </v-btn> -->
          <!-- </v-flex> -->

          <v-col cols="12" class="d-flex flex-wrap align-baseline justify-end py-0 my-0 mx-0 px-0">
            <v-btn color="white" class="mx-1 my-1" small elevation="2" @click="clear_selection">
              <v-icon class="mr-1" small>mdi-selection-off</v-icon>
              Clear Selected
            </v-btn>
            <v-btn color="white" class="mx-1 my-1" small elevation="2" @click="reset">
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              Reset
            </v-btn>

            <v-btn color="primary" class="mx-0 my-1" small elevation="2" :disabled="!valid || selected.length < 1 || messageRequestSent" @click="validate">
              <v-icon class="mr-1" small>mdi-send</v-icon>
              Send
            </v-btn>
          </v-col>
        </v-form>
      </v-sheet>
      <!-- </v-card> -->
    </v-col>
  </v-row>
</template>

<script>
import SectionDatatable from "../sendgrid/SectionDatatable.vue";
export default {
  name: "BulkSMS",
  components: {
    SectionDatatable,
  },
  data: () => ({
    // new fields
    headers: [
      { text: "Name", align: "start", sortable: true, value: "name" },
      { text: "Role", value: "role" },
      { text: "Type", value: "type" },
      { text: "Category", value: "category" },
      { text: "Mobile", value: "formattedMobile" },
      { text: "Agency", value: "agency" },
      { text: "Details", value: "data-table-expand" },
    ],

    requestSent: false,

    // snackbar fields
    showSnackbar: false,
    snackbarText: "Message Progress...",
    snackbarText2: "",

    // message form data
    messageRequestSent: false,
    valid: false,

    sms: {
      users: [],
      message: "",
      title: "",
      from: "123456789",
    },
    checkbox: false,
    checkboxRules: [(v) => !!v || "Confirm sending of message?"],
    messageRules: [(v) => !!v || "Message can not be empty"],
    titleRules: [(v) => !!v || "Campaign Title can not be empty"],

    // Table selection
    selected: [],
    smsTitle: "",
    excludeCampaignFilter: [],
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {},
  mounted() {
    this.initialize();
  },
  methods: {
    // new methods
    async initialize() {
      this.sms.from = this.$store.state.users.user.assigned_number;
    },

    // validating and sending SMS
    async validate() {
      try {
        this.snackbarClose();
        // waiting for combobox to update before firing
        await new Promise((r) => setTimeout(r, 200));

        // validating form data
        let validated = this.$refs.form.validate();
        if (!validated) {
          return this.snackbarShow("Kindly select atleast 1 contact & type a message", true);
        }

        // validating from number
        if (!this.sms.from) {
          return this.snackbarShow("Kindly select a number to send campaign from", true);
        }

        // validating contacts to send Campaign to
        this.sms.users = this.selected.map((contact) => contact.id);
        if (this.sms.users.length < 1 || !this.sms.message) {
          this.status = "Kindly select atleast 1 contact & type a message";
          return;
        }

        // setting progress bar
        this.requestSent = true;
        this.sms.exclude_campaigns = this.excludeCampaignFilter;

        // sending request for sms
        let res = await this.axios.post("/api/bsms/sendbulksms", this.sms);
        console.log(res.data);
        // handling response
        this.requestSent = false;
        this.snackbarShow(res.data.message, true);
        if (!res.data.success) return;
        // setting default values again
        this.selected = [];
        this.sms = { message: "", users: [], title: this.sms.title };
        this.checkbox = false;
        this.resetValidation();

        // creating sms title in store
        this.$store.dispatch("createSMSTitle", this.sms.title);
      } catch (error) {
        this.requestSent = false;
        this.snackbarShow("Messags could not be sent: check your internet connection", true);
        console.log(error);
      }
    },
    // v-form validation reset functions
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    // simple text editing function
    messageConfirm(contacts_length) {
      return "Confirm message to " + contacts_length + " contacts?";
    },
    // simple text generating function
    selectedFilter(contacts_length) {
      return contacts_length + " contact(s) selected";
    },
    // adding predefined shortcodes to the text area
    addShortcode(symbol) {
      this.sms.message += symbol + " ";
    },
    // snackbar setting function
    snackbarShow(text2, show) {
      this.showSnackbar = show;
      this.snackbarText2 = text2;
    },
    // snackbar unsetting function
    snackbarClose() {
      this.showSnackbar = false;
      this.snackbarText2 = "";
    },
    clear_selection() {
      this.$refs.ref_datatable_component.clear_selection();
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
/* .datatable-height{
height: 90vh;
} */

.send-div .v-btn {
  text-transform: initial;
  width: 120px;
  float: right;
}
.send-div .v-btn .v-icon {
  width: 20px;
}
</style>
