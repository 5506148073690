<template>
  <v-row >
    <v-col cols="12" class="ma-0 pa-0">
      <router-view :users="users" :usersRequest="usersRequest"></router-view>
    </v-col>
  </v-row>
</template>

<script>
import SubMenu from "../../../components/Submenu";
export default {
  name: "Users-Wrapper",
  data() {
    return {
      menuTitle: "Users",
      menuItems: [
        {
          title: "Manage Account",
          action: "mdi-account",
          active: false,
          items: [
            { title: "Edit Account", route: "/dashboard/users/manageaccount/changesettings" },
            { title: "Reset Password", route: "/dashboard/users/manageaccount/changepassword" },
          ],
        },
        {
          title: "Manage Users",
          action: "mdi-account-box-multiple",
          active: false,
          items: [
            { title: "Add, Edit or Remove Users", route: "/dashboard/users/manageusers/changeusersettings" },
            // { title: "View Activity", route: "/dashboard/users/manageusers/viewactivity" },
          ],
        },
      ],
      // contacts for child components
      users: [],
      usersRequest: false,
    };
  },
  components: {
    SubMenu,
  },
  methods: {
    fetchUsers() {
      this.usersRequest = true;
      this.axios
        .get("/api/users/")
        .then((res) => {
          this.users = res.data.users;
          this.usersRequest = false;
        })
        .catch((err) => {
          this.usersRequest = false;
          console.log("Error while fetching users: users_main");
          console.log(err);
        });
    },
  },
  watch: {
    users: {
      handler: function() {
        console.log("users changed in main");
      },
      deep: true,
    },
  },
  created() {
    if (this.users.length < 1) {
      console.log("Fetching users in main");
      this.fetchUsers();
    }
  },
};
</script>
<style scoped></style>
