const axios = require("axios");
const config = require("../../config/app.json");

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

module.exports.sms = {
  state: () => ({
    smsTitles: [],
    smsFrom: [],
  }),
  getters: {
    getSMSTitles: (state) => {
      return state.smsTitles;
    },
  },
  mutations: {
    ["SET_SMS_TITLES"](state, smsTitles) {
      state.smsTitles = smsTitles;
    },
    ["CREATE_SMS_TITLE"](state, smsTitle) {
      state.smsTitles.push(smsTitle);
    },
  },
  actions: {
    async initializeSMS({ dispatch }) {
      await dispatch("setSMSTitles");
    },
    setSMSTitles({ state, commit }) {
      axios
        .get("/api/bsms/getsmstitles")
        .then((res) => {
          commit("SET_SMS_TITLES", res.data.smsTitles);
          state.smsFrom = res.data.smsFrom;
        })
        .catch((err) => {
          console.log(err);
          console.log("Error in fetching SMS Titles: SMS store");
          dispatch("setSnackBar", "Could not fetch SMS data from database, kindly check your connection or reload the page");
        });
    },
    createSMSTitle({ commit }, smsTitle) {
      commit("CREATE_SMS_TITLE", smsTitle);
    },
  },
};
