const axios = require("axios");
const config = require("../../config/app.json");

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

module.exports.contacts = {
  state: () => ({
    shortcodes: [],
    contact_filter_data: {
      states: [],
      suburbs: [],
      types: [],
      categories: [],
      opt_outs: [],
    },
  }),
  getters: {
    getShortcodes: (state) => {
      return state.shortcodes;
    },
    getFilterItems: (state) => {
      return state.contact_filter_data;
    },
  },
  mutations: {
    ["SET_SHORTCODES"](state, shortcodes) {
      state.shortcodes = shortcodes;
    },
  },
  actions: {
    async initializeContacts({ dispatch }) {
      await dispatch("setShortcodes");
    },
    setShortcodes({ commit, dispatch }) {

      axios
        .get("/api/contacts/getshortcodes")
        .then((res) => commit("SET_SHORTCODES", res.data.shortcodes))
        .catch((err) => {
          console.log(err);
          dispatch("setSnackBar", "Could not fetch shortcodes from database, kindly check your connection or reload the page");
        });
    },
    get_contact_filter_data({ state, commit, dispatch }) {
      axios
        .get("/api/contacts/getcontactsfilterdata")
        .then((res) => {
          console.log(res.data);
          state.contact_filter_data.state = res.data.states;
          state.contact_filter_data.suburb = res.data.suburbs;
          state.contact_filter_data.type = res.data.types;
          state.contact_filter_data.category = res.data.categories;
          state.contact_filter_data.opt_outs = res.data.optouts;
        })
        .catch((err) => {
          console.log(err);
          dispatch("setSnackBar", "Filter data could not be fetched, kindly check your internet connection.");
        });
    },
  },
};
