<template>
  <v-row>
    <v-overlay :value="loading" :z-index="20004">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing..</span>
      </v-snackbar>
    </v-overlay>

    <!-- progress snackbar -->
    <v-snackbar
      :value="showSnackbar"
      timeout="-1"
      :vertical="true"
      :multi-line="true"
      app
      bottom
      right
      style="opacity: 0.9; z-index: 2005"
      content-class="main-snackbar-content d-flex flex-column align-start justify-start"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template v-if="typeof snackbarText2 == 'string'">
          <div>{{ snackbarText2 }}</div>
        </template>
        <template v-else>
          <template v-for="(value, key) in snackbarText2">
            <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</div>
          </template>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Tabs Col Start -->
    <v-col cols="12" sm="12" md="12" class="small-font">
      <v-stepper v-model="step" class="mx-2 rounded-0 small-font" non-linear>
        <v-stepper-header>
          <v-stepper-step step="1" :editable="editableSteps">
            Choose Contacts
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2" :editable="editableSteps">
            Setup Campaign
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" :editable="editableSteps">
            Choose Template
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step step="4" editable>
            Preview & Send
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!-- step: choose audiance -->
          <v-stepper-content step="1">
            <!-- <v-col cols="12" sm="12" md="12"> -->
            <!-- <v-card class="mx-2"> -->
            <v-container fluid>
              <!-- FILTERS ROW START-->
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="12" md="4" class="d-flex justify-end align-center">
                  <v-badge :value="selected.length" :content="selected.length" bottom overlap class="mr-4">
                    <v-btn color="blue" dark text outlined class="option_badge_button" :disabled="!selected.length" @click="selected = []">
                      <v-icon>mdi-select-off</v-icon>
                    </v-btn>
                  </v-badge>

                  <v-badge :value="selected.length" :content="selected.length" bottom overlap color="green" class="mr-8">
                    <v-btn color="green" dark text outlined class="option_badge_button" :disabled="!selected.length" @click="step = 2">
                      <v-icon>mdi-check-circle</v-icon>
                    </v-btn>
                  </v-badge>
                </v-col>
              </v-row>

              <!-- FILTERS ROW END-->
              <v-row>
                <v-col cols="12" md="12">
                  <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="contacts"
                    item-key="id"
                    :options.sync="options"
                    :server-items-length="contactsCount"
                    sort-by="address"
                    class="mx-0 my-2 px-0 fill-height"
                    outlined
                    show-select
                    :single-select="singleSelect"
                    show-expand
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    fixed-header
                    dense
                    flat
                    :footer-props="{ 'items-per-page-options': [25, 50, 75, 100, 200] }"
                  >
                    <template v-slot:top>
                      <!-- filters start -->
                      <v-row v-if="showFilters" class="px-5 py-1 align-baseline" no-gutters>
                        <!-- filters section: strat -->
                        <v-col cols="12" xs="12" sm="12" md="9">
                          <v-row class="align-center my-1 py-1" v-for="(filter, index) in includeFilters" :key="index">
                            <!-- Councils search -->
                            <v-col cols="12" xs="12" sm="12" md="3" class="mr-0 pr-0 py-0">
                              <v-select
                                class="rounded-0 text-capitalize small-font"
                                v-model="includeFilters[index].column"
                                :items="includeFilterColumns"
                                label="column"
                                hide-details
                                auto-select-first
                                dense
                                solo
                              ></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="2" class="mx-0 px-0 py-1">
                              <v-select
                                class="rounded-0 small-font"
                                v-model="includeFilters[index].condition"
                                :items="
                                  includeFilterIntFields.includes(includeFilters[index].column) ? includeFilterIntConditions : includeFilterTextConditions
                                "
                                label="condition"
                                hide-details
                                auto-select-first
                                dense
                                solo
                              ></v-select>
                            </v-col>

                            <!-- Case: condition === contains -->
                            <v-col cols="12" xs="12" sm="12" md="6" class="mx-0 px-0 py-1" v-if="includeFilters[index].condition == 'contains'">
                              <v-autocomplete
                                v-if="includeFIltersShowAutocomplete.includes(includeFilters[index].column)"
                                multiple
                                class="rounded-0 text-capitalize small-font"
                                @focus="getSuggestionsFromStore(includeFilters[index])"
                                v-model="includeFilters[index].value"
                                @keyup="getSuggestions(includeFilters[index], $event.target.value)"
                                :items="suggestions"
                                :item-text="includeFilters[index].column"
                                label="value"
                                :return-object="false"
                                hide-details
                                dense
                                solo
                                autocomplete="false"
                                small-chips
                                deletable-chips
                              ></v-autocomplete>
                              <v-combobox
                                v-else
                                multiple
                                class="rounded-0 text-capitalize small-font"
                                v-model="includeFilters[index].value"
                                @keyup="getSuggestions(includeFilters[index], $event.target.value)"
                                @focus="getSuggestionsFromStore(includeFilters[index])"
                                :items="suggestions"
                                :item-text="includeFilters[index].column"
                                label="value"
                                :return-object="false"
                                hide-details
                                dense
                                solo
                                autocomplete="false"
                                small-chips
                                deletable-chips
                              ></v-combobox>
                            </v-col>

                            <!-- Case: condition === range -->
                            <v-col cols="12" xs="12" sm="12" md="6" class="mx-0 px-0 d-flex" v-if="includeFilters[index].condition == 'range'">
                              <v-text-field
                                type="number"
                                class="rounded-0 text-capitalize small-font"
                                v-model="includeFilters[index].range[0]"
                                label="Min"
                                hide-details
                                dense
                                solo
                              ></v-text-field>
                              <v-text-field
                                type="number"
                                class="rounded-0 text-capitalize small-font"
                                v-model="includeFilters[index].range[1]"
                                label="Max"
                                hide-details
                                dense
                                solo
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="1" class="mx-0 px-0 py-0 small-font">
                              <v-btn
                                color="red"
                                dark
                                text
                                outlined
                                class="py-5 rounded-0 text-capitalize"
                                @click="includeFilters.length > 1 ? includeFilters.splice(index, 1) : ''"
                              >
                                <v-icon small>mdi-trash-can</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <!-- action buttons -->
                        <v-col cols="12" xs="12" sm="12" md="3" class="d-flex  flex-wrap justify-end">
                          <v-btn
                            color="primary"
                            dark
                            text
                            outlined
                            class="py-5 rounded-0 text-capitalize"
                            @click="loadContacts()"
                            @keyup.enter="loadContacts()"
                          >
                            <v-icon>mdi-home-search</v-icon>
                          </v-btn>
                          <v-btn
                            color="primary"
                            dark
                            text
                            outlined
                            class="py-5 rounded-0 text-capitalize"
                            @click="includeFilters.push({ column: '', condition: 'contains', value: [], range: [null, null] })"
                          >
                            <v-icon>mdi-filter-plus</v-icon>
                          </v-btn>
                          <v-btn
                            color="primary"
                            dark
                            text
                            outlined
                            class="py-5 rounded-0 text-capitalize"
                            @click="includeFilters = [{ column: '', condition: 'contains', value: [], range: [null, null] }]"
                          >
                            <v-icon>mdi-filter-remove</v-icon>
                          </v-btn>
                        </v-col>
                        <!-- filters section: end -->
                      </v-row>
                      <!-- filters end -->
                    </template>
                    <!-- expansion template: start -->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-simple-table dense class="ma-5" flat>
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <td style="width: 200px;" class="font-weight-bold text-capitalize">
                                  <button>Markets</button>
                                </td>
                                <td>
                                  <div v-for="(market, index) in item.markets" :key="'em' + index">{{ market }}</div>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </td>
                    </template>
                    <!-- expansion template: end -->
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
            <!-- </v-card> -->
            <!-- </v-col> -->
          </v-stepper-content>

          <!-- step: setup campaign -->
          <v-stepper-content step="2">
            <v-row class="px-5 mt-8">
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="3" class="d-flex justify-end align-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" dark text outlined class="option_badge_button"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                  </template>

                  <v-list dense class="rounded-0 text-capitalize">
                    <v-list-item link @click="setCampaignDefaults()">
                      <v-list-item-title>Save As Default</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="getCampaignDefaults()">
                      <v-list-item-title>Apply Defaults</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="cancelCampaign()">
                      <v-list-item-title>Cancel Campaign</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn color="green" dark text outlined class="option_badge_button" @click="step = 3"><v-icon>mdi-check-circle</v-icon></v-btn>
              </v-col>

              <!-- Add / Edit Modal -->
            </v-row>
            <v-row class="px-5">
              <v-spacer></v-spacer>
              <v-col cols="12" md="6" class="px-5 pb-5 mb-5 form-content">
                <div class="text-left heading my-5 font-weight-bold">Exclude Contacts</div>
                <v-row class="align-center">
                  <!-- contacts selected row -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-subheader>Contacts Selected</v-subheader>
                  </v-col>
                  <v-col cols="12" md="8" class="py-0">
                    <div>{{ selected.length }}</div>
                  </v-col>

                  <!-- exclude campaign row -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-subheader>Exclude Campaigns</v-subheader>
                  </v-col>
                  <v-col cols="12" md="8" class="py-0">
                    <v-autocomplete
                      v-model="excludeCampaigns"
                      :items="previousCampaigns"
                      item-text="campaign_name"
                      item-value="id"
                      :disabled="!selected.length"
                      label="Select Campaigns To Exclude"
                      color="white"
                      dense
                      solo
                      hide-details
                      class="rounded-0"
                      small-chips
                      deletable-chips
                      multiple
                      :return-object="false"
                    ></v-autocomplete>
                  </v-col>
                  <!-- <v-col cols="12" md="4" class="py-0">
                    <v-subheader></v-subheader>
                  </v-col>
                  <v-col cols="12" md="8" class="py-0">
                    <div>
                      <v-btn color="blue" dark text outlined class="option_badge_button" style="text-transform: unset;" @click="excludeCampaignContacts()">
                        
                        Apply Exclusion
                      </v-btn>
                    </div>
                  </v-col> -->
                </v-row>

                <v-form ref="form" lazy-validation>
                  <!-- basic fields template -->
                  <div class="text-left heading my-5 font-weight-bold">Campaign Information</div>
                  <v-row class="align-center" v-for="(field, key) in campaign.settings" :key="key">
                    <v-col cols="12" md="4" class="py-0">
                      <v-subheader>{{ settings_labels[key] }}</v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="py-0">
                      <!-- showing merge fields -->
                      <template v-if="fields_with_merge_tags.includes(key)">
                        <template v-for="field in merge_fields">
                          <v-chip
                            v-if="showMergeTags.includes(field.tag)"
                            :key="field.tag"
                            @click="campaign.settings[key] += ' *|' + field.tag + '|*'"
                            color="blue"
                            small
                            chip
                            label
                            outlined
                            class="mr-1 rounded-0"
                            style="margin: 10px 10px 10px 0px !important;"
                          >
                            {{ field.name }}
                          </v-chip>
                        </template>
                      </template>
                      <v-text-field
                        v-if="type(campaign.settings[key]) !== 'boolean'"
                        v-model="campaign.settings[key]"
                        hide-details
                        class="rounded-0"
                        solo
                        dense
                      ></v-text-field>
                      <v-checkbox v-else v-model="campaign.settings[key]"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <div class="text-left heading my-5 font-weight-bold">Campaign Tracking Information</div>
                  <v-row class="align-center" v-for="(field, key) in campaign.tracking" :key="key">
                    <v-col cols="12" md="4" class="py-0">
                      <v-subheader>{{ tracking_labels[key] }}</v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="py-0">
                      <v-text-field
                        v-if="type(campaign.tracking[key]) !== 'boolean'"
                        v-model="campaign.tracking[key]"
                        hide-details
                        class="rounded-0"
                        solo
                        dense
                      ></v-text-field>
                      <v-checkbox v-else v-model="campaign.tracking[key]"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-stepper-content>

          <!-- step: choose template -->
          <v-stepper-content step="3">
            <v-row class="px-5 mt-5">
              <v-spacer></v-spacer>

              <v-col cols="12" sm="12" md="2" class="d-flex justify-end align-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" dark text outlined class="option_badge_button"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    <!-- <v-btn v-bind="attrs" v-on="on" color="white" block medium class="rounded-0 text-capitalize">Actions</v-btn> -->
                  </template>

                  <v-list dense class="rounded-0 text-capitalize">
                    <v-list-item link @click="setTemplateDefaults()">
                      <v-list-item-title>Save As Default</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="getTemplateDefaults()">
                      <v-list-item-title>Apply Defaults</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="cancelCampaign()">
                      <v-list-item-title>Cancel Campaign</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn block class="ml-4 rounded-0 white--text" color="green" small @click="createCampaign()">Create Campaign</v-btn>
              </v-col>
              <!-- Add / Edit Modal -->
            </v-row>
            <v-row class="px-5">
              <v-spacer></v-spacer>
              <v-col cols="12" md="8" class="px-5 pb-5 mb-5 form-content">
                <v-form ref="template_form" lazy-validation>
                  <!-- basic fields template -->
                  <template>
                    <div class="text-left heading my-5 font-weight-bold">Email Content</div>
                    <v-row class="align-center">
                      <v-col cols="12" md="12" class="py-0">
                        <div class="mb-2 x-small">
                          <template v-for="field in merge_fields">
                            <v-chip
                              v-if="showMergeTags.includes(field.tag)"
                              :key="field.tag"
                              @click="template.content += ' *|' + field.tag + '|*'"
                              color="blue"
                              small
                              chip
                              label
                              outlined
                              class="mr-1 rounded-0"
                            >
                              {{ field.name }}
                            </v-chip>
                          </template>
                        </div>
                      </v-col>
                      <v-col cols="12" md="12" class="py-0">
                        <!-- optional features: shortcodes for merge fields, save default template & content -->
                        <v-textarea v-model="template.content" rows="8" hide-details auto-grow clearable class="rounded-0" solo dense></v-textarea>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-10"></v-divider>
                  </template>

                  <div class="text-left heading my-5 font-weight-bold">Choose Email Template</div>
                  <v-row class="align-center">
                    <v-col cols="12" md="4" class="py-0"><v-subheader>Template Type</v-subheader></v-col>
                    <v-col cols="12" md="8" class="py-0">
                      <v-select
                        v-model="template.type"
                        item-text="key"
                        item-value="value"
                        :items="template_types"
                        hide-details
                        class="rounded-0"
                        solo
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col cols="12" md="12" class="py-3 px-3">
                      <v-alert icon="mdi-information-outline" prominent text type="info" class="small-font">
                        {{ template_notes[template.type] }}
                        <br />
                        <b>
                          Make sure shortcode '[text1]' is placed in the template you are using, shortcode will be replaced by the content & can be repeated
                          multiple times in same template.
                        </b>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col cols="12" md="12" class="py-10">
                      <v-data-table
                        v-model="template.selected_template"
                        :headers="template_headers"
                        :items="templates"
                        :search="template_search"
                        class="grey--text rounded-0 v-sheet v-card"
                        show-select
                        :single-select="template_singleSelect"
                        :items-per-page="50"
                        fixed-header
                        dense
                        :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
                      >
                        <!-- Actions slot -->
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-menu bottom offset-x left dense>
                            <!-- Options Menu Button -->
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon class="" color="blue" v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                            </template>
                            <!-- Option Menu List -->
                            <v-list class="rounded-0" dense>
                              <v-list-item @click="editTemplate(item)">
                                <v-list-item-content>
                                  <v-list-item-title class="item-actions-menu">
                                    <v-icon small color="blue">mdi-pencil</v-icon>
                                    Edit Template
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="template.type.includes('remap')" @click="viewTemplate(item)">
                                <v-list-item-content>
                                  <v-list-item-title class="item-actions-menu">
                                    <v-icon small color="blue">mdi-eye</v-icon>
                                    View Template
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-stepper-content>

          <!-- step: preview & send -->
          <v-stepper-content step="4">
            <v-row class="px-5 mt-5">
              <v-spacer></v-spacer>
              <v-col cols="12" md="2" class="d-flex justify-end align center">
                <v-menu offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" dark text outlined class="option_badge_button"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                  </template>

                  <v-list dense class="rounded-0 text-capitalize">
                    <v-list-item link :disabled="!created_campaign.id" @click="viewCampaign()">
                      <v-list-item-title>Edit / View Campaign</v-list-item-title>
                    </v-list-item>

                    <v-list-item link :disabled="!created_campaign.id" @click="cancelCampaign()">
                      <v-list-item-title>Send New Campaign</v-list-item-title>
                    </v-list-item>

                    <v-list-item :disabled="created_campaign_sent || !created_campaign.id" link @click="deleteCreatedCampaign()">
                      <v-list-item-title class="red--text">Delete Campaign</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  :disabled="created_campaign_sent || !created_campaign.id"
                  @click="sendCreatedCampaign()"
                  block
                  class="ml-4 rounded-0 white--text"
                  color="green"
                  small
                >
                  Send Campaign
                </v-btn>
              </v-col>
              <!-- Add / Edit Modal -->
            </v-row>
            <v-row class="px-5">
              <v-spacer></v-spacer>
              <v-col cols="12" md="8" class="px-5 pb-5 mb-5 form-content">
                <!-- <v-form ref="form" lazy-validation> -->

                <div class="text-left heading my-5 font-weight-bold">Send Test Email</div>
                <v-row class="align-center">
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Enter email"
                      :disabled="!created_campaign.id"
                      v-model="testEmail"
                      hide-details
                      class="rounded-0"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-btn @click="sendTestEmail()" :disabled="!created_campaign.id" block color="primary" class="ml-4 rounded-0 white--text">
                      Send Test Email
                    </v-btn>
                  </v-col>
                </v-row>

                <!-- Campaign Recipient Fields -->
                <div class="text-left heading my-5 font-weight-bold">Recipients</div>
                <v-row class="align-center">
                  <template v-if="Object.keys(created_campaign.recipients).length">
                    <v-col cols="12" md="4" class="py-0"><v-subheader>List Name</v-subheader></v-col>
                    <v-col cols="12" md="8" class="py-0">
                      <v-subheader>{{ created_campaign.recipients.list_name }}</v-subheader>
                    </v-col>

                    <v-col cols="12" md="4" class="py-0"><v-subheader>Selected Contacts</v-subheader></v-col>
                    <v-col cols="12" md="8" class="py-0">
                      <v-subheader>{{ created_campaign.recipients.recipient_count }}</v-subheader>
                    </v-col>
                  </template>
                </v-row>
                <v-divider></v-divider>
                <!-- Campaign Recipient Fields -->
                <div class="text-left heading my-5 font-weight-bold">Template & Content</div>
                <v-row class="align-center">
                  <template v-if="Object.keys(created_campaign.template).length">
                    <v-col cols="12" md="4" class="py-0"><v-subheader>Template Name</v-subheader></v-col>
                    <v-col cols="12" md="8" class="py-0">
                      <v-subheader>{{ created_campaign.template.selected_template[0].name }}</v-subheader>
                    </v-col>

                    <v-col cols="12" md="4" class="py-0"><v-subheader>Content</v-subheader></v-col>
                    <v-col cols="12" md="8" class="py" style="height: fit-content !important;">
                      <v-subheader>{{ created_campaign.template.content }}</v-subheader>
                    </v-col>
                  </template>
                </v-row>
                <v-divider></v-divider>
                <!-- Campaign Settings fields -->
                <div class="text-left heading my-5 font-weight-bold">Campaign Information</div>
                <v-row class="align-center" v-for="(field, key) in created_campaign.settings" :key="key">
                  <v-col cols="12" md="4" class="py-0">
                    <v-subheader>{{ settings_labels[key] }}</v-subheader>
                  </v-col>
                  <v-col cols="12" md="8" class="py-0">
                    <v-text-field
                      disabled
                      v-if="type(created_campaign.settings[key]) !== 'boolean'"
                      v-model="created_campaign.settings[key]"
                      hide-details
                      class="rounded-0"
                      solo
                      dense
                    ></v-text-field>
                    <v-checkbox disabled v-else v-model="created_campaign.settings[key]"></v-checkbox>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- Campaign Tracking fields -->
                <div class="text-left heading my-5 font-weight-bold">Campaign Tracking Information</div>
                <v-row class="align-center">
                  <template v-for="(field, key) in created_campaign.tracking">
                    <v-col cols="12" md="3" class="py-0" :key="'pttc1' + key">
                      <v-subheader>{{ tracking_labels[key] }}</v-subheader>
                    </v-col>
                    <v-col cols="12" md="1" class="py-0" :key="'pttc2' + key">
                      <v-text-field
                        disabled
                        v-if="type(created_campaign.tracking[key]) !== 'boolean'"
                        v-model="created_campaign.tracking[key]"
                        hide-details
                        class="rounded-0"
                        solo
                        dense
                      ></v-text-field>
                      <v-checkbox disabled v-else v-model="created_campaign.tracking[key]"></v-checkbox>
                    </v-col>
                  </template>
                </v-row>
                <v-divider></v-divider>
                <!-- </v-form> -->
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MailChimpSendEmails",
  data() {
    return {
      step: 1,
      editableSteps: true,
      requestSent: false,
      contacts: [],
      contactsCount: 0,

      excludeCampaigns: [],
      previousCampaigns: [],
      merge_fields: [],
      showMergeTags: ["FNAME", "LNAME"],

      // table data
      options: {
        page: 1,
        itemsPerPage: 50,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [false],
      },

      // table attributes
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Agency", value: "agency" },
        { text: "Markets", value: "data-table-expand", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selected: [],
      singleSelect: false,
      singleExpand: true,
      expanded: [],

      // main campaign object
      campaign: {
        type: "",
        content_type: "",
        recipients: { list_id: "", segment_opts: { saved_segment_id: "" } },
        settings: {
          title: "",
          to_name: "",
          from_name: "",
          subject_line: "",
          preview_text: "",
          reply_to: "",
          auto_footer: true,
          // template_id: 447182,
        },
        tracking: {
          opens: true,
          html_clicks: true,
          text_clicks: true,
        },
      },
      settings_labels: {
        title: "Campaign Name",
        to_name: "To (Name)",
        from_name: "From (Name)",
        subject_line: "Email Subject",
        preview_text: "Email Sub-Heading",
        reply_to: "Reply To (Email)",
        auto_footer: "MailChimp Footer",
      },
      fields_with_merge_tags: ["to_name"],
      tracking_labels: {
        opens: "Email Opens",
        html_clicks: "Email HTML Clicks",
        text_clicks: "Email Text Clicks",
      },

      // template step data
      templates: [],
      template: {
        type: "remap_custom",
        selected_template: [],
        content: "",
      },
      template_types: [
        { key: "MailChimp Template", value: "mailchimp_template" },
        { key: "MailChimp Custom", value: "mailchimp_custom" },
        { key: "Remap Custom", value: "remap_custom" },
      ],

      template_headers: [
        { text: "Template", value: "name" },
        { text: "Type", value: "content_type" },
        { text: "Drag & Drop", value: "drag_and_drop" },
        { text: "Actions", value: "actions" },
      ],
      template_search: "",
      template_singleSelect: true,

      // preview & send campaign step data
      created_campaign: {
        id: "",
        recipients: {},
        settings: {},
        tracking: {},
        template: {},
      },
      testEmail: "",
      created_campaign_sent: false,

      // filter fields
      showFilters: true,
      //default || starting values for filters
      includeFilters: [{ column: "", condition: "contains", value: [], range: [null, null] }],
      includeFiltersRow: { column: "", condition: "contains", value: [], range: [null, null] },
      includeFilterTextConditions: ["contains"],
      includeFilterIntConditions: ["range", "contains"],
      includeFilterTextFields: [],
      includeFilterIntFields: ["size", "postal_code"],
      includeFilterColumns: [
        { text: "Markets", value: "markets" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Agency", value: "agency" },
      ],
      includeFiltersStoreSuggestions: ["markets", "isOptOut"],
      includeFIltersShowAutocomplete: ["markets"],
      suggestionRequestSent: false,
      suggestions: [],

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      template_notes: {
        mailchimp_template:
          "MailChimp Template category represents all saved templates in MailChimp Account which can also be seen in 'Campaign > Email templates' tagged as 'Classic'. Once a campaign is created, templates can not be edited by drag & drop template editor in MailChimp",
        mailchimp_custom:
          "MailChimp Custom category represents all templates that have been uploaded by 'Use your own' uploader in MailChimp Account which can also be seen in 'Campaign > Email templates' tagged as 'Code your own'",
        remap_custom:
          "Remap Custom category represents all templates that have been uploaded directly to Remap using Template Manager page (coming soon), they can be used in cross platform applications",
      },
    };
  },
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    options: {
      async handler() {
        this.loadContacts();
      },
      deep: true,
    },
    "template.type": {
      handler() {
        this.getTemplatesByType();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadContacts();
    this.getTemplatesByType();
    this.getCampaignDefaults();
    this.getTemplateDefaults();
    this.getMergeFields();
    this.getPreviousCampaigns();
    // await this.getTemplates();
  },
  methods: {
    // contacts step methods
    async excludeCampaignContacts() {
      try {
        // validating campaign & contacts values
        if (!this.excludeCampaigns.length) {
          return this.setSnackbar(true, "Alert", "No campaign selected");
        }
        if (!this.selected.length) {
          return this.setSnackbar(true, "Alert", "No contact selected");
        }

        this.requestSent = true;
        console.log(this.previousCampaigns);
        console.log(this.excludeCampaigns);
        // reducing contacts array to emails only
        let contacts = this.selected.map((contact) => {
          return contact.email;
        });
        let res = await this.axios.post("/api/mailchimp/getexcludedcontactsbycampaigns", { campaigns: this.excludeCampaigns, contacts });
        this.requestSent = false;
        this.setSnackbar(true, "Alert", res.data.message);
        console.log(res.data);

        if (res.data.success) {
          this.selected = this.selected.filter((contact) => {
            return !res.data.excluded_contacts.includes(contact.email);
          });
        }
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.$store.dispatch("setSnackBar", "Unable to send exclude campaigns request - Kindly check your internet connection");
      }
    },
    // campaign step methods
    saveCampaignDefaults() {
      console.log(this.campaign);
    },
    // template step methods
    async getTemplatesByType() {
      try {
        this.requestSent = true;
        let template_type = this.template.type;
        let res = await this.axios.post("/api/mailchimp/gettemplatesbytypes", { template_type });
        if (!res.data.success) {
          return this.setSnackbar(true, "Error", res.data.message);
        }
        this.templates = res.data.templates;
        this.requestSent = false;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        console.log("Unable to fetch templates - Kindly check your internet connection");
      }
    },
    async createCampaign() {
      try {
        if (this.requestSent) return;

        // validating campaign data
        let validate = this.validateCampaignData();
        if (!validate.valid) {
          return this.setSnackbar(true, "Alert", validate.message);
        }

        let contacts = this.selected.map((contact) => contact.id);

        this.requestSent = true;
        let res = await this.axios.post("/api/mailchimp/createcampaign", {
          contacts,
          campaign: this.campaign,
          template: this.template,
          excludedCampaigns: this.excludeCampaigns,
        });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) {
          return this.setSnackbar(true, "Error", res.data.message);
        }

        this.created_campaign = res.data.campaign;
        this.step++;
        this.editableSteps = false;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "Unable to send campaign creation request - Kindly check your internet connection");
      }
    },
    validateCampaignData() {
      try {
        // validating contacts
        if (!this.selected.length) {
          return { valid: false, message: "No contacts selected for campaign" };
        }

        // validating campaign data
        for (let [key, value] of Object.entries(this.campaign.settings)) {
          if (typeof value !== "boolean" && !value) {
            return { valid: false, message: `${this.settings_labels[key]} field in step 2 can not be empty` };
          }
        }

        // validateing selected template
        if (!this.template.selected_template.length) {
          return { valid: false, message: "No template selected for campaign" };
        }

        // validating template content
        if (!this.template.content) {
          return { valid: false, message: "Email content can not be empty" };
        }

        // valid campaign
        return { valid: true, message: "Creating Campaign" };
      } catch (error) {
        console.log(error);
        this.setSnackbar(true, "Alert", "Unable validate campaign  - kindly check all the fields or try refreshing the page");
      }
    },
    // preview & send campaign
    async sendCreatedCampaign() {
      try {
        if (!this.created_campaign.id) {
          return this.$store.dispatch("setSnackBar", "No campaign found");
        }
        this.requestSent = true;
        let campaignId = this.created_campaign.id;
        let res = await this.axios.post("/api/mailchimp/sendcreatedcampaign", { campaignId });
        this.$store.dispatch("setSnackBar", res.data.message);
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "Alert", res.data.message);
        }
        this.created_campaign_sent = true;
        this.$router.push({ name: "MailchimpCampaigns" });
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Alert", "Unable to send campaign request - Kindly check your internet connection");
      }
    },
    async deleteCreatedCampaign() {
      try {
        if (!this.created_campaign.id) {
          return this.$store.dispatch("setSnackBar", "Invalid Campaign");
        }
        this.requestSent = true;
        let campaignId = this.created_campaign.id;
        let res = await this.axios.post("/api/mailchimp/deletecampaign", { campaignId });
        this.$store.dispatch("setSnackBar", res.data.message);
        this.requestSent = false;
        if (res.data.success) this.cancelCampaign();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.$store.dispatch("setSnackBar", "Unable to send delete campaign request - Kindly check your internet connection");
      }
    },
    async sendTestEmail() {
      try {
        if (!this.created_campaign.id || !this.testEmail) {
          return this.$store.dispatch("setSnackBar", "Test Email is required");
        }
        this.requestSent = true;
        let email = this.testEmail;
        let campaignId = this.created_campaign.id;
        let res = await this.axios.post("/api/mailchimp/sendtestemail", { email, campaignId });
        console.log(res.data);
        this.$store.dispatch("setSnackBar", res.data.message);
        this.requestSent = false;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.$store.dispatch("setSnackBar", "Unable to send test email - Kindly check your internet connection");
      }
    },
    async getCampaigns() {
      try {
        console.log("Getting Campaigns:");
        let res = await this.axios.get("/api/mailchimp/getcampaigns");
        console.log(res.data);
      } catch (error) {
        console.log("Unable to send campaign request - Kindly check your internet connection");
        console.log(error);
      }
    },
    async getTemplates() {
      try {
        this.requestSent = true;
        let res = await this.axios.get("/api/mailchimp/gettemplates");
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "Error", res.data.message);
        }
        console.log(res.data);
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "Unable to send request - Kindly check your internet connection");
      }
    },
    async setCampaignDefaults() {
      try {
        this.requestSent = true;
        let campaign = this.campaign;
        let res = await this.axios.post("/api/mailchimp/setcampaigndefaults", { campaign });
        this.$store.dispatch("setSnackBar", res.data.message);
        this.requestSent = false;
      } catch (error) {
        this.requestSent = false;
        console.log("Unable to send campaign request - Kindly check your internet connection");
        console.log(error);
      }
    },
    async getCampaignDefaults() {
      try {
        this.requestSent = true;
        let res = await this.axios.get("/api/mailchimp/getcampaigndefaults");
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "Error", res.data.message);
        }
        this.campaign = res.data.campaign;
      } catch (error) {
        this.requestSent = false;
        this.setSnackbar(true, "Error", "Unable to send request - Kindly check your internet connection");
        console.log(error);
      }
    },
    async setTemplateDefaults() {
      try {
        this.requestSent = true;
        let template = this.template;
        let res = await this.axios.post("/api/mailchimp/settemplatedefaults", { template });
        this.$store.dispatch("setSnackBar", res.data.message);
        this.requestSent = false;
      } catch (error) {
        this.requestSent = false;
        console.log("Unable to send template update request - Kindly check your internet connection");
        console.log(error);
      }
    },
    async getTemplateDefaults() {
      try {
        this.requestSent = true;
        let res = await this.axios.get("/api/mailchimp/gettemplatedefaults");
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "Error", res.data.message);
        }

        this.template = res.data.template;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "Unable to send request - Kindly check your internet connection");
      }
    },
    editTemplate(template) {
      console.log(template);
      if (this.template.type.includes("mailchimp") && template.id) {
        let url = `https://us10.admin.mailchimp.com/templates/edit?id=${template.id}`;
        window.open(url, "blank");
      }
    },
    async viewTemplate(template) {
      try {
        console.log(template);
        console.log(this.template.type);
        if (!template.id || this.template.type != "remap_custom") {
          return this.setSnackbar(true, "Alert", "Invalid template for viewing - only Remap Custom templates can be viewed");
        }

        this.requestSent = true;
        let res = await this.axios.post("/api/mailchimp/getlocaltemplatecontent", { template, template_type: this.template.type });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) {
          return this.setSnackbar(true, "Alert", res.data.message);
        }

        console.log("HTML length: ", res.data.template_html.length);
        let newwindow = window.open("", "blank");
        newwindow.document.write(res.data.template_html);
      } catch (error) {
        this.requestSent = false;
        this.setSnackbar(true, "Error", "Can not send view template request - Kindly check your internet connection");
        console.log(error);
      }
    },

    async getMergeFields() {
      try {
        this.requestSent = true;
        let res = await this.axios.get("/api/mailchimp/getmergefields");
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "Error", res.data.message);
        }

        this.merge_fields = res.data.merge_fields;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "Unable to fetch shortcodes - Kindly check your internet connection");
      }
    },
    async getPreviousCampaigns() {
      try {
        this.requestSent = true;
        let res = await this.axios.get("/api/mailchimp/getpreviouscampaigns");
        this.requestSent = false;
        if (!res.data.success) {
          return this.setSnackbar(true, "Error", res.data.message);
        }
        this.previousCampaigns = res.data.campaigns;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "Unable to send request - Kindly check your internet connection");
      }
    },
    cancelCampaign() {
      this.selected = [];
      this.excludeCampaigns = [];
      this.created_campaign_sent = false;
      this.created_campaign = { id: "", recipients: {}, settings: {}, tracking: {}, template: {} };
      this.editableSteps = true;
      this.step = 1;

      // this.loadContacts();
      this.getCampaignDefaults();
      this.getTemplateDefaults();
    },
    viewCampaign() {
      if (!this.created_campaign.web_id) return;
      let url = `https://us10.admin.mailchimp.com/campaigns/edit?id=${this.created_campaign.web_id}`;
      window.open(url, "_blank");
    },
    marketAutocompleteText(text) {
      return `${text.suburb}, ${text.state}, ${text.postcode}`;
    },
    type(value) {
      return typeof value;
    },
    // filters methods
    // new functions for component
    async loadContacts() {
      try {
        // checking for other requests
        if (this.requestSent) return;
        this.requestSent = true;
        await new Promise((r) => setTimeout(r, 200));
        // sending request for contacts
        let res = await this.axios.post("/api/contacts/getcontacts", {
          options: this.options,
          filters: this.includeFilters,
        });
        this.requestSent = false;

        if (!res.data.success) {
          return this.setSnackbar(true, "Alert", res.data.message);
        }

        this.contacts = res.data.contacts;
        this.contactsCount = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "Contacts could not be loaded: check your internet connection");
      }
    },
    // getting suggestions while typing
    async getSuggestions(input_filter, input_value) {
      if (input_value.length < 3 || !input_filter.column || this.suggestionRequestSent || this.includeFiltersStoreSuggestions.includes(input_filter.column))
        return;
      this.suggestionRequestSent = true;
      let filter = Object.assign({}, input_filter);
      filter.value = input_value;
      let res = await this.axios.post("/api/contacts/getsuggestions", { filter });
      this.suggestionRequestSent = false;
      if (!res.data.success) return;
      this.suggestions = res.data.suggestions;
    },
    // getting suggestions or combobox-items for predefined values like markets
    async getSuggestionsFromStore(filter) {
      filter.value = [];
      this.suggestions = [];
      let column = filter.column;

      if (this.includeFiltersStoreSuggestions.includes(column)) {
        if (column.toLowerCase() == "markets") column = "suburb";
        this.suggestions = this.$store.getters.getFilterItems[column];
        if (column == "suburb") {
          this.suggestions = this.$store.getters.getFilterItems[column].map((suburb) => suburb.suburb + ", " + suburb.state + ", " + suburb.postcode);
        }
        if (column == "isOptOut") {
          this.suggestions = ["Yes", "No"];
        }
      }
    },
    setSnackbar(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
  },
};
</script>

<style scoped>
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-chip {
  margin: 2px !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .small-font *:not(i){
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-btn.v-btn--disabled .v-icon {
  color: lightblue !important;
}
.option_badge_button {
  min-width: fit-content !important;
  padding: 10px !important;
}
.v-stepper__content {
  padding: 0px !important;
}
</style>
