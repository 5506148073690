<template>
  <div>
    <app-bar class="app-appbar fill-height"></app-bar>
    <nav-drawer class="app-sidebar"></nav-drawer>
    <v-container fluid class="pa-2 app-container">
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import AppBar from "../../components/AppBar";
import NavDrawer from "../../components/NavDrawer";

export default {
  components: {
    AppBar,
    NavDrawer,
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  async mounted() {
    // initializing app
    // this.$store.dispatch("initializeSocketio");
    this.$store.dispatch("fetchUser");
    this.$store.dispatch("get_contact_filter_data");
    // initializing app data
    // await this.$store.dispatch("initializeListings");
    // this.$store.dispatch("initializeSitecheckListings");
    this.$store.dispatch("initializeContacts");
    this.$store.dispatch("initializeSMS");
  },
};
</script>

<style>
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu";
}
.app-container {
  background-color: #f2f2f2;
}
.app-appbar {
  background-color: #4049bf !important;
  color: white;
  background-color: var(--v-bstopbar-base) !important;
}
.app-sidebar {
  color: white;
  background-color: var(--v-bssidebar-base) !important;
}
.main-snackbar-content {
  padding-bottom: 0px !important;
}
</style>
