<template>
  <v-row>
    <v-overlay :value="loading" style="z-index: 20001">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar :value="showSnackbar" timeout="-1" :vertical="true" app bottom right style="opacity: 0.9; z-index: 2005" content-class="main-snackbar-content">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template v-if="typeof snackbarText2 == 'string'">
          <span>{{ snackbarText2 }}</span>
        </template>
        <template v-else>
          <template v-for="(value, key) in snackbarText2">
            <span v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</span>
          </template>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-col cols="12">
      <v-sheet color="white" elevation="3" rounded class="d-flex align-center justify-center" style="min-height: 90vh">
        <v-card max-width="500px" width="500px" class="pa-5 rounded-2">
          <v-form ref="form" v-model="valid">
            <!-- Select new or pre-saved number -->
            <v-select v-model="sms.from" :items="numbers" item-text="text" item-value="value" label="Send From" class="rounded-2 text-capitalize my-3" hide-details dense solo></v-select>
            <v-select
              v-model="newNumber"
              :items="newNumberOptions"
              item-text="text"
              item-value="value"
              label="Select Type (New or Pre-saved Number)"
              class="rounded-2 text-capitalize my-3"
              hide-details
              dense
              solo
            ></v-select>

            <!-- select if pre-saved number -->
            <v-autocomplete
              v-if="!newNumber"
              v-model="selectedContact"
              @keyup="getSuggestions($event.target.value)"
              :items="suggestions"
              item-text="name"
              return-object
              hide-details
              dense
              solo
              label="Type contact name to select"
              class="rounded-2 text-capitalize my-3"
              :loading="suggestionRequestSent"
            >
              <template v-slot:selection="data">
                <small class="small-font">{{ data.item.name }} - {{ data.item.formattedMobile }}</small>
              </template>
              <template v-slot:item="data">
                <small class="small-font">{{ data.item.name }} - {{ data.item.formattedMobile }}</small>
              </template>
            </v-autocomplete>

            <!-- select country if new number -->
            <v-select
              v-if="newNumber"
              v-model="country"
              :items="countries"
              return-object
              item-text="cty"
              item-value="code"
              :rules="countryRules"
              label="Select Country"
              required
              hide-details
              dense
              solo
              class="rounded-0 my-3"
            ></v-select>

            <!-- enter number if new number -->
            <v-text-field v-if="newNumber" v-model="sms.mobile" type="number" :counter="18" label="Mobile Number" required hide-details dense solo clearable class="rounded-2 my-3"></v-text-field>

            <!-- shorcodes if pre-saved number -->
            <div class="my-3 x-small" v-if="!newNumber">
              <v-chip
                v-for="(shortcode, index) in $store.getters.getShortcodes"
                :key="index"
                @click="addShortcode(shortcode.symbol)"
                color="primary"
                small
                chip
                dense
                label
                outlined
                class="mr-1 rounded-2"
              >
                {{ shortcode.symbol }}
              </v-chip>
            </div>
            <!-- message text area -->
            <v-textarea label="Message" v-model="sms.message" :rules="messageRules" :counter="160" required clearable class="rounded-2" hide-details dense solo></v-textarea>
            <!-- confirmation check box -->
            <v-checkbox v-model="checkbox" :rules="checkboxRules" label="Confirm message?" required hide-details></v-checkbox>

            <!-- buttons / options section -->
            <!-- <v-col class="d-flex justify-end">
              <v-btn color="grey" outlined text class="ml-4 rounded-2" @click="reset"> Cancel </v-btn>
              <v-btn :disabled="!valid || requestSent" color="red" outlined text class="ml-4 rounded-2" @click="validate" align-end> Send </v-btn>
            </v-col> -->

            <v-col cols="12" class="d-flex justify-end py-0 my-0 mt-2 mx-0 px-0 profile-div">
              <v-btn color="white" class="mx-1 my-1" small elevation="2" @click="reset">
                <v-icon class="mr-1" small>mdi-cancel</v-icon>
                Reset
              </v-btn>

              <v-btn color="primary" class="mx-0 my-1" small elevation="2" :disabled="!valid || requestSent" @click="validate">
                <v-icon class="mr-1" small>mdi-content-save</v-icon>
                Send
              </v-btn>
            </v-col>
          </v-form>
        </v-card>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Send-SMS",
  data: () => ({
    valid: false,
    requestSent: false,
    suggestionRequestSent: false,
    suggestions: [],

    // snackbar fields
    showSnackbar: false,
    snackbarText: "Message Progress",
    snackbarText2: "",

    sms: {
      newNumber: false,
      from: "",
      contact: "",
      mobile: "",
      message: "",
      countrycode: "au",
    },
    defaultSMS: {
      newNumber: false,
      from: "",
      contact: 0,
      mobile: "",
      message: "",
      countrycode: { cty: "Australia", code: "au" },
    },

    checkbox: false,
    checkboxRules: [(v) => !!v || "You must agree to continue!"],
    countryRules: [(v) => !!v || "Country is required"],
    messageRules: [(v) => !!v || "Message is required"],
    country: { cty: "Australia", code: "au" },
    countries: [
      { cty: "Australia", code: "au" },
      { cty: "Pakistan", code: "pk" },
      { cty: "USA", code: "us" },
      { cty: "New Zeland", code: "nz" },
    ],
    newNumber: false,
    newNumberOptions: [
      { text: "New Number", value: true },
      { text: "Saved Contacts", value: false },
    ],
    selectedContact: {},
    numbers: ["+61480008208"],
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    newNumber(newVal, oldVal) {
      this.sms.mobile = "";
      this.selectedContact = "";
    },
  },
  mounted() {
    this.sms.from = this.$store.state.users.user.assigned_number;
    this.defaultSMS.from = this.$store.state.users.user.assigned_number;
  },
  methods: {
    async validate() {
      this.setSnackbar(false, "", "");
      let validated = this.$refs.form.validate();

      if (!validated) {
        return this.setSnackbar(true, "Alert", "Kindly check the check all the fields and tick the confirm checkbox");
      }

      if (this.newNumber && !this.sms.mobile) {
        return this.setSnackbar(true, "Alert", "Kindly fill in the mobile number");
      }

      if (!this.sms.from) {
        return this.setSnackbar(true, "Alert!", "Kindly select a number to send message from - Number should be registered in BurstSMS as well");
      }

      if (!this.newNumber && !this.selectedContact) {
        return this.setSnackbar(true, "Alert", "Kindly select a contact to send SMS to");
      }

      this.sms.contact = this.selectedContact && this.selectedContact.id;
      if (!this.newNumber && !this.sms.contact) {
        return this.setSnackbar(true, "Alert", "Kindly select a contact to send SMS to");
      }

      this.sms.countrycode = this.newNumber && this.country.code;
      this.sms.newNumber = this.newNumber;
      this.status = "";
      this.requestSent = true;
      console.log(this.sms);
      this.requestSent = false;
      return;
      this.axios
        .post("/api/bsms/sendsms", this.sms)
        .then((res) => {
          this.requestSent = false;
          this.showSnackbar = true;
          this.snackbarText2 = res.data.message;

          if (!res.data.success) return;
          this.sms = Object.assign({}, this.defaultSMS);
          this.select = this.sms.countrycode;
          this.selectedContact = {};
          this.reset();
          this.resetValidation();
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.showSnackbar = true;
          this.snackbarText2 = "Message could not be sent: Kindly check your internet connection";
        });
    },
    async getSuggestions(input_value) {
      if (input_value.length < 3) return;
      this.suggestionRequestSent = true;
      let filter = Object.assign({}, { column: "name", value: input_value });
      let res = await this.axios.post("/api/contacts/getsuggestionsquicksms", { filter });
      this.suggestionRequestSent = false;
      if (!res.data.success) return;
      this.suggestions = this.suggestions.concat(res.data.suggestions);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addShortcode(symbol) {
      this.sms.message += symbol + " ";
    },
    setSnackbar(show, heading, message) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = message;
    },
  },
};
</script>
<style scoped>
* {
  letter-spacing: normal;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}

.profile-div .v-btn {
  text-transform: initial;
  width: 146px;
  float: right;
}
.profile-div .v-btn .v-icon {
  width: 20px;
}
</style>
