import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#6BC048", // Modify the primary color
        secondary: "#00FF00", // Modify the secondary color
        accent: "#0000FF", // Modify the accent color
        error: "#FF0000", // Modify the error color
        // Add or modify other color values as needed

        bstopbar: "#6BC048",
        bssidebar: "#6BC048",
        bstopbarfont: "#992486",
        bssidebarfont: "#f8f4eb",

        bsprimary1: "#ffffff",
        bssecondary1: "#000000",

        bsprimary2: "#f8f4eb",
        bssecondary2: "#6BC048",

        bssuccess: "#4973bd", //blue
        bsdanger: "#ed5b72",
        bserror: "#ed5b72",

      },
    },
  },
});
