<template>
  <!-- Data table for contct list: start -->

  <v-flex flat>
    <template>
      <v-data-table
        :headers="headers"
        :items="messages"
        sort-by="to"
        class="mx-5 my-2 px-5 v-sheet v-card fill-height"
        outlined
        :loading="loading"
        loading-text="Loading... Please wait"
        :items-per-page="10"
        fixed-header
        
        :footer-props="{ 'items-per-page-options': [10, 30, 50, 100] }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <!-- <h4 class="text-uppercase grey--text my-2">SMS Details</h4> -->
            <v-spacer></v-spacer>
            <v-btn color="primary" dark text outlined class="mb-2 font-weight-black" @click="initialize">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            {{ errorStatus }}
          </v-btn>
        </template>
        <!-- SMS Delieved At SLot -->
        <template v-slot:[`item.sendAt`]="{ item }">
          <span class="text-capitalize">{{ item.sendAt | formatDate }}</span>
        </template>
        <!-- SMS Sent At SLot -->
        <template v-slot:[`item.createdAt`]="{ item }">
          <span class="text-capitalize">{{ item.createdAt | formatDate }}</span>
        </template>
      </v-data-table>
    </template>
    <!-- Data table for contct list: end -->
  </v-flex>
</template>

<script>
import moment from "moment";
export default {
  name: "SMSDetails",
  data: () => ({
    headers: [
      { text: "To", align: "start", value: "contact.name" },
      { text: "Message", value: "message", width: 600 },
      { text: "Title", value: "title" },
      { text: "Status", value: "dlrStatus" },
      { text: "Sent", value: "createdAt" }
      //   { text: "From", value: "from" },
    ],
    messages: [],
    messagesRequest: false,

    // table loading
    loading: true,
    errorStatus: "Lodaing....",
  }),

  watch: {
    "messages.length": function() {
      if (this.messages.length < 1) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
  },

  created() {
    if (this.messages.length < 1 && !this.messagesRequest) this.initialize();
  },

  methods: {
    initialize() {
      this.loading = true;
      this.messagesRequest = true;
      this.messages = [];

      this.axios
        .get("/api/bsms/messages")
        .then((res) => {
          this.messages = res.data.messages;
          console.log(this.messages);
        })
        .catch((err) => {
          this.errorStatus = "Error while fetching SMS Details, Reload?";
        });
    },
  },
  filters: {
    formatDate: function(date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style scoped>
::v-deep th.text-start {
  min-width: 100px;

  max-width: 300px;
}
</style>
