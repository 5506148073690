<template>
  <!-- Data table for contct list: start -->
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <v-overlay :value="loading" style="z-index: 20001">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <v-col cols="12" class="ma-0 pa-0">
      <v-sheet color="white" elevation="3" rounded class="d-flex align-start justify-center mr-2 pa-2 table-sheet" style="min-height: 90vh; width: 100%">
        <v-data-table
          :headers="headers"
          :items="campaigns"
          :options.sync="options"
          :server-items-length="campaigns_length"
          item-key="id"
          style="border: none; width: 100%"
          height="73vh"
          fixed-header
          dense
          show-expand
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :footer-props="{ 'items-per-page-options': [30, 50, 100, 200] }"
          @click:row="(v) => $router.push({ name: 'SGViewBulkEmailDetails', query: { campaign: v } })"
        >
          <template v-slot:top>
            <div class="d-flex flex-wrap justify-end align-center my-0 mb-3 table-top">
              <v-btn small color="primary" dark text outlined class="py-5" @click="initialize()"><v-icon small>mdi-refresh</v-icon></v-btn>
            </div>
          </template>

          <template v-slot:no-data> No campaigns to list </template>

          <!-- expansion template: start -->
          <template v-slot:[`item.createdAt`]="{ item }">
            <div>{{ format_date(item.createdAt) }}</div>
          </template>

          <template v-slot:expanded-item="{ item }">
            <div class="w-100 pa-2 ma-3 table-expanded-div">
              <div class="expanded-text" v-html="format_settings(item.settings)"></div>
            </div>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  name: "EmailCampaignsTransactional",
  data: () => ({
    // new fields
    campaigns: [],
    campaigns_length: 0,
    headers: [
      { text: "Title", value: "title", align: "start" },
      { text: "Created", value: "createdAt", align: "center" },
      { text: "Details", value: "data-table-expand", align: "center" },
    ],
    options: {
      page: 1,
      itemsPerPage: 30,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    // table expansion
    expanded: [],
    singleExpand: true,
    requestSent: false,

    messages: [],
    messagesRequest: false,
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },

  watch: {
    // new fields
    options() {
      this.loadCampaigns();
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    // new methods
    async initialize() {
      this.options.page = 1;
      await this.loadCampaigns();
    },
    // new functions for component
    async loadCampaigns() {
      try {
        // checking for other requests
        if (this.requestSent) return;
        this.requestSent = true;
        await new Promise((r) => setTimeout(r, 200));
        // sending request for contacts
        let res = await this.axios.post("/api/email/getcampaignstransactional", {
          options: this.options,
          filters: [],
        });
        this.requestSent = false;
        console.log(res.data);
        if (res.data.success) {
          this.campaigns = res.data.campaigns;
          this.campaigns_length = res.data.count;
        }
      } catch (error) {
        this.requestSent = false;
        this.$store.dispatch("setSnackBar", "Campaigns could not be loaded: check your internet connection");
        console.log(error);
      }
    },
    get_days(dt) {
      return moment(dt).fromNow();
    },
    get_date(dt) {
      return moment(dt).format("DD MMM YY");
    },
    format_date(dt) {
      if (!dt) return "";
      return moment(dt).format("DD MMM YY HH:mm:ss");
    },
    format_settings(settings) {
      let fs = "";
      for (const [k, v] of Object.entries(settings)) if (k != "stats" && typeof v == "string") fs += `<b>${k.replace("_", " ")}:</b> ${v} <br>`;
      for (const [key, value] of Object.entries(settings.stats)) fs += `<b>${key.replace("_", " ")}:</b> ${value} <br>`;
      return fs;
    },
  },
};
</script>

<style scoped>
::v-deep th.text-start {
  min-width: 100px;
  max-width: 300px;
}
::v-deep td,
.table-expanded-div {
  font-size: 13px !important;
  font-weight: 500;
  cursor: pointer;
}

.table-top .v-btn {
  text-transform: initial;
  /* font-size: 13px !important; */
  font-weight: 500;
}

::v-deep .expanded-text b {
  text-transform: capitalize;
}
</style>
