<template>
  <!-- <v-navigation-drawer v-model="drawer" app :mini-variant.sync="mini" mini-variant-width="100"> -->
  <v-navigation-drawer app permanent v-model="drawer" :mini-variant.sync="mini" expand-on-hover>
    <v-list-item>
      <v-icon color="white" @click="mini = !mini">mdi-menu</v-icon>
    </v-list-item>

    <v-divider></v-divider>
    <v-list dense shaped nav>
      <template v-for="(item, index) in links">
        <v-list-group color="white" :key="item.title" v-model="item.active" :prepend-icon="item.action" v-if="$store.state.users.user && item.permission.includes($store.state.users.user.role)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title color="white" class="small-font">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-slot:prependIcon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </template>

          <v-list-item v-for="child in item.items" :key="child.title" :to="child.to">
            <v-list-item-icon>
              <v-icon color="white">{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="small-font">{{ child.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider v-if="item.sectionEnd" :key="index + 100" class="my-1"></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      mini: true,
      links: [
        {
          title: "General",
          icon: "mdi-home-outline",
          permission: ["user", "admin"],
          items: [
            { title: "Home", to: { name: "Home" }, icon: "mdi-home" },
            // { title: "Stats", to: { name: "Stats" }, icon: "mdi-file-chart-outline" },
          ],
          sectionEnd: true,
        },

        // {
        //   title: "Agent Contacts",
        //   icon: "mdi-contacts",
        //   permission: ["user", "admin"],
        //   items: [
        //     // { title: "Manage Contact Lists", to: "/dashboard/services/burstsms/contactlist", icon: "mdi-format-list-bulleted" },
        //     { title: "Manage Contacts", to: "/dashboard/services/burstsms/contacts", icon: "mdi-account-arrow-right" },
        //   ],
        // },
        {
          title: "SMS",
          icon: "mdi-message-text-outline",
          sectionEnd: true,
          permission: ["user", "admin"],
          items: [
            { title: "Send Bulk SMS", to: "/dashboard/services/burstsms/sendbulksms", icon: "mdi-comment-text-multiple" },
            { title: "Send Quick SMS", to: "/dashboard/services/burstsms/sendsms", icon: "mdi-comment-flash" },
            { title: "Campaigns", to: "/dashboard/services/burstsms/campaigns", icon: "mdi-inbox-arrow-up" },
            { title: "Inbox", to: { name: "SMSInbox" }, icon: "mdi-inbox" },
          ],
        },
        {
          title: "Email",
          icon: "mdi-email-outline",
          sectionEnd: true,
          permission: ["user", "admin"],
          items: [
            // { title: "MC Send Emails", to: "/dashboard/services/mailchimp/sendemail", icon: "mdi-email-multiple-outline" },
            // { title: "MC Campaigns", to: "/dashboard/services/mailchimp/campaigns", icon: "mdi-bullhorn-outline" },
            // { title: "MC Contacts", to: "/dashboard/services/mailchimp/contacts", icon: "mdi-account-group-outline" },
            // { title: "Create Campaign", to: { name: "EmailCampaignComing" }, icon: "mdi-email-alert" },
            { title: "Send Bulk Trans", to: { name: "SGSendBulkEmail" }, icon: "mdi-email-multiple" },
            { title: "View Bulk Trans", to: { name: "SGViewBulkEmail" }, icon: "mdi-mailbox-up" },
          ],
        },
        {
          title: "Users",
          icon: "mdi-account-multiple-outline",
          sectionEnd: true,
          permission: ["admin"],
          items: [{ title: "Manage Users", to: "/dashboard/users/manageusers/changeusersettings", icon: "mdi-account-multiple-plus" }],
        },
        // {
        //   title: "Settings",
        //   icon: "mdi-hammer-screwdriver",
        //   sectionEnd: true,
        //   permission: ["user", "admin"],
        //   items: [{ title: "Test Component", to: "/dashboard/testcomponent", icon: "mdi-account-multiple-plus" }],
        // },
        // {
        //   title: "Documentation",
        //   icon: "mdi-book-information-variant",
        //   permission: ["user", "admin"],
        //   items: [
        //     { title: "User Manual", to: "/dashboard/documentation", icon: "mdi-book-open" },
        //     { title: "Technical Docs", to: "/dashboard/reference", icon: "mdi-server-network" },
        //   ],
        // },
      ],
    };
  },
};
</script>

<style scoped>
* {
  color: white;
}
.group-heading {
  /* background-color: #dfedff; */
  /* margin-bottom: 5px; */
  margin-top: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-heading-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.group-heading-text {
  font-weight: 700 !important;
}
.v-icon.v-icon {
  color: black;
}
::v-deep .v-label,
::v-deep .small-font,
::v-deep .small-font *,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .v-list-group__header__append-icon i {
  color: white;
}
::v-deep .v-list-group__header__prepend-icon {
  margin-right: 12px !important;
}
</style>
