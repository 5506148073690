<template>
  <!-- Data table for contct list: start -->

  <v-flex flat>
    <template>
      <v-overlay :value="loading" style="z-index:20001;">
        <v-snackbar v-model="loading" timeout="-1" centered>
          <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
          <span class="py-3 px-2">Processing...</span>
        </v-snackbar>
      </v-overlay>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="contacts"
        item-key="id"
        :options.sync="options"
        :server-items-length="contacts_length"
        sort-by="name"
        class="mx-5 my-2 px-5 v-sheet v-card fill-height"
        outlined
        show-expand
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        fixed-header
        dense
        :footer-props="{ 'items-per-page-options': [50, 100, 250, 500] }"
      >
        <template v-slot:top>
          <v-row>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="5" class="d-flex justify-end">
                <v-btn color="primary" @click="showFilters = !showFilters" dark text outlined class="py-5 rounded-0 text-capitalize">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
                <v-btn color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" @click="initialize()"><v-icon>mdi-refresh</v-icon></v-btn>
                <v-btn color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" @click="createItem()">
                  <v-icon>mdi-account-multiple-plus-outline</v-icon>
                </v-btn>
                <!-- Edit Dialog Box: start -->
                <v-dialog v-model="dialog" max-width="700px" flat class="rounded-0 no-transition">
                  <v-card class="rounded-0">
                    <v-card-title class="text-center">
                      <h4 class="text-uppercase text-center grey--text py-1" style="width:100%;">{{ formTitle }}</h4>
                    </v-card-title>

                    <v-card-text class="py-1">
                      <v-container>
                        <v-row>
                          <!-- <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-center" style="flex-wrap:wrap;width:100%;"> -->
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              :value="editedItem.firstName"
                              @change="(v) => (editedItem.firstName = v)"
                              label="Firstname"
                              class="rounded-0"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              :value="editedItem.lastName"
                              @change="(v) => (editedItem.lastName = v)"
                              label="Lastname"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              :value="editedItem.mobile"
                              @change="(v) => (editedItem.mobile = v)"
                              label="Mobile"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              :value="editedItem.phone"
                              @change="(v) => (editedItem.phone = v)"
                              label="Phone"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              :value="editedItem.agency"
                              @change="(v) => (editedItem.agency = v)"
                              label="Agency"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              :value="editedItem.email"
                              @change="(v) => (editedItem.email = v)"
                              label="Email"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-autocomplete
                              :value="editedItem.markets"
                              @change="(v) => (editedItem.markets = v)"
                              :items="$store.getters.getFilterItems.suburb"
                              :item-text="(item) => item.suburb + ', ' + item.state + ', ' + item.postcode"
                              :return-object="false"
                              hide-details
                              clearable
                              hide-selected
                              multiple
                              persistent-hint
                              small-chips
                              label="Markets"
                              class="rounded-0"
                              prepend-inner-icon="mdi-map-marker-right-outline"
                              dense
                              solo
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-autocomplete
                              :value="editedItem.type"
                              @change="(v) => (editedItem.type = v)"
                              :items="['residential', 'commercial', 'acreage / agricultural']"
                              hide-details
                              clearable
                              hide-selected
                              persistent-hint
                              small-chips
                              label="Agent Type"
                              class="rounded-0"
                              dense
                              solo
                            ></v-autocomplete>
                          </v-col>
                          <!-- <v-col cols="12" sm="6" md="6">
                            <v-text-field :value="editedItem.address" @change="(v) => (editedItem.address = v)" label="Address" hide-details dense solo></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field :value="editedItem.agency_website" @change="(v) => (editedItem.agency_website = v)" label="Agency Website" hide-details dense solo></v-text-field>
                          </v-col> -->
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              :value="editedItem.profileLink"
                              @change="(v) => (editedItem.profileLink = v)"
                              label="Profile Link"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              :value="editedItem.agencyLink"
                              @change="(v) => (editedItem.agencyLink = v)"
                              label="Agency Link"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                          <!-- </v-form> -->
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions class="px-7 d-flex align-start">
                      <v-alert dense outlined type="info" v-if="dialogStatus" class="mx-2 rounded-0 text-body2">{{ dialogStatus }}</v-alert>
                      <v-spacer></v-spacer>
                      <v-btn color="grey" outlined text @click="close" class="rounded-0">
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-4" text outlined @click="save()" :disabled="requestSent" class="rounded-0">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <!-- Edit Dialog Box: end -->

              <!-- Delete Dialog Box: start -->
              <v-dialog v-model="dialogDelete" max-width="500px" width="500px">
                <v-card>
                  <v-card-title class="text-center my-2 justify-center">Are you sure you want to delete this contact?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" outlined text @click="closeDelete" style="letter-spacing:normal;">Cancel</v-btn>
                    <v-btn color="red" outlined text @click="deleteItemConfirm" style="letter-spacing:normal;">Yes</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </v-row>
          <v-row v-if="showFilters" class="px-4 py-1 align-baseline">
            <!-- filters section: strat -->
            <v-col cols="12" xs="12" sm="12" md="9">
              <v-row class="align-center my-1 py-1" v-for="(filter, index) in includeFilters" :key="index">
                <!-- Column -->
                <v-col cols="12" xs="12" sm="12" md="3" class="mr-0 pr-0">
                  <v-select
                    class="rounded-0 text-capitalize"
                    v-model="includeFilters[index].column"
                    :items="includeFilterColumns"
                    item-text="a"
                    label="column"
                    hide-details
                    auto-select-first
                    dense
                    solo
                  ></v-select>
                </v-col>
                <!-- condition -->
                <v-col cols="12" xs="12" sm="12" md="2" class="mx-0 px-0">
                  <v-select
                    class="rounded-0"
                    v-model="includeFilters[index].condition"
                    :items="includeFilterIntFields.includes(includeFilters[index].column) ? includeFilterIntConditions : includeFilterTextConditions"
                    label="condition"
                    hide-details
                    auto-select-first
                    dense
                    solo
                  ></v-select>
                </v-col>

                <!-- Case: condition === contains -->
                <v-col cols="12" xs="12" sm="12" md="6" class="mx-0 px-0" v-if="includeFilters[index].condition == 'contains'">
                  <v-autocomplete
                    v-if="includeFIltersShowAutocomplete.includes(includeFilters[index].column)"
                    multiple
                    class="rounded-0 text-capitalize"
                    @focus="getSuggestionsFromStore(includeFilters[index])"
                    v-model="includeFilters[index].value"
                    @keyup="getSuggestions(includeFilters[index], $event.target.value)"
                    :items="suggestions"
                    :item-text="includeFilters[index].column"
                    label="value"
                    :return-object="false"
                    hide-details
                    dense
                    solo
                    autocomplete="false"
                    small-chips
                    deletable-chips
                  ></v-autocomplete>
                  <v-combobox
                    v-else
                    multiple
                    class="rounded-0 text-capitalize"
                    v-model="includeFilters[index].value"
                    @keyup="getSuggestions(includeFilters[index], $event.target.value)"
                    @focus="getSuggestionsFromStore(includeFilters[index])"
                    :items="suggestions"
                    :item-text="includeFilters[index].column"
                    label="value"
                    :return-object="false"
                    hide-details
                    dense
                    solo
                    autocomplete="false"
                    small-chips
                    deletable-chips
                  ></v-combobox>
                </v-col>

                <!-- Case: condition === range -->
                <v-col cols="12" xs="12" sm="12" md="6" class="mx-0 px-0 d-flex" v-if="includeFilters[index].condition == 'range'">
                  <v-text-field
                    type="number"
                    class="rounded-0 text-capitalize"
                    v-model="includeFilters[index].range[0]"
                    label="Min"
                    hide-details
                    dense
                    solo
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    class="rounded-0 text-capitalize"
                    v-model="includeFilters[index].range[1]"
                    label="Max"
                    hide-details
                    dense
                    solo
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" class="mx-0 px-0 py-0">
                  <v-btn
                    color="red"
                    dark
                    text
                    outlined
                    class="py-5 rounded-0 text-capitalize"
                    @click="includeFilters.length > 1 ? includeFilters.splice(index, 1) : ''"
                  >
                    <v-icon small>mdi-trash-can</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <!-- action buttons -->
            <v-col cols="12" xs="12" sm="12" md="3" class="d-flex  flex-wrap justify-end">
              <v-btn color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" @click="loadContacts()" :loading="suggestionRequestSent">
                <v-icon>mdi-home-search</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                dark
                text
                outlined
                class="py-5 rounded-0 text-capitalize"
                @click="includeFilters.push({ column: '', condition: 'contains', value: [], range: [null, null] })"
              >
                <v-icon>mdi-filter-plus</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                dark
                text
                outlined
                class="py-5 rounded-0 text-capitalize"
                @click="includeFilters = [{ column: '', condition: 'contains', value: [], range: [null, null] }]"
              >
                <v-icon>mdi-filter-remove</v-icon>
              </v-btn>
            </v-col>
            <!-- filters section: end -->
          </v-row>
        </template>

        <!-- Agents Profile Link SLot -->
        <template v-slot:[`item.profile_link`]="{ item }">
          <a target="_blank" :href="item.profile_link" class="grey--text">
            <v-icon>mdi-account-arrow-right</v-icon>
          </a>
        </template>

        <!-- Agents agency profile link slot -->
        <template v-slot:[`item.agencyLink`]="{ item }">
          <a v-if="item.agencyLink" target="_blank" :href="'https://' + item.agencyLink" class="grey--text">
            <v-icon>mdi-home-group</v-icon>
          </a>
        </template>

        <!-- expansion template: start -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-layout>
              <!-- <v-flex md-2>Markets:</v-flex> -->
              <v-flex md12>
                <v-layout row class="pa-5">
                  <template v-for="(market, index) in item.markets">
                    <v-flex class="px-1" md4 :key="index">{{ market }}</v-flex>
                  </template>
                </v-layout>
              </v-flex>
            </v-layout>
          </td>
        </template>
        <!-- expansion template: end -->

        <!-- Optout SLlot -->
        <template v-slot:[`item.isOptOut`]="{ item }">
          <v-btn color="primary" :ref="item.id" outlined @click="contactOptOut(item)" dark text class="text-capitalize">
            {{ !item.isOptOut ? "Opt Out" : "Opt In" }}
          </v-btn>
        </template>

        <!-- Actions slot -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>

        <!-- Empty table slot -->
        <template v-slot:no-data>
          <div>
            {{ errorStatus }}
          </div>
        </template>
      </v-data-table>
    </template>
    <!-- Data table for contct list: end -->
  </v-flex>
</template>

<script>
export default {
  name: "TestComponent",
  data: () => ({
    // data-table fields
    contacts: [],
    selected: [],
    options: {
      page: 1,
      itemsPerPage: 250,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    contacts_length: 0,

    dialog: false,
    dialogDelete: false,
    newContact: true,
    deleteId: 0,

    editedIndex: -1,
    editedItem: {
      firstName: "",
      lastName: "",
      agency: "",
      phone: "",
      mobile: "",
      email: "",
      markets: "",
      profileLink: "",
      agencyLink: "",
      type: "",
      // address: "",
      // agency_website: "",
    },
    defaultItem: {
      firstName: "",
      lastName: "",
      // name: "",
      agency: "",
      phone: "",
      mobile: "",
      email: "",
      markets: "",
      profileLink: "",
      agencyLink: "",
      type: "",
      // address: "",
      // agency_website: "",
    },
    requestSent: false,

    // table loading
    errorStatus: "Sorry, no contacts found for this search",
    dialogStatus: "",

    // New filters
    // include filters
    showFilters: true,
    includeFilters: [{ column: "", condition: "contains", value: [], range: [null, null] }],
    includeFiltersRow: { column: "", condition: "contains", value: [], range: [null, null] },
    includeFilterTextConditions: ["contains"],
    includeFilterIntConditions: ["range", "contains"],
    includeFilterTextFields: [],
    includeFilterIntFields: ["size", "postal_code"],
    includeFilterColumns: ["name", "firstName", "lastName", "formattedMobile", "markets", "phone", "email", "isOptOut", "agency", "agencyLink"],
    includeFiltersStoreSuggestions: ["markets", "isOptOut"],
    includeFIltersShowAutocomplete: ["markets"],
    suggestions: [],
    suggestionRequestSent: false,

    // market expansion
    expanded: [],
    singleExpand: true,
  }),

  computed: {
    formTitle() {
      return this.newContact ? "Add Contact" : "Edit Contact";
    },
    headers() {
      return [
        { text: "Agent Name", value: "name" },
        { text: "Mobile", value: "formattedMobile" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Agency", value: "agency" },
        { text: "Agency Link", value: "agencyLink" },
        { text: "Markets", value: "data-table-expand" },
        { text: "Optout", value: "isOptOut" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    loading() {
      return this.requestSent;
    },
  },

  watch: {
    options() {
      this.loadContacts();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.options.page = 1;
      this.includeFilters = [{ column: "", condition: "contains", value: [], range: [null, null] }];
      await this.loadContacts();
    },
    // new functions for component
    async loadContacts() {
      try {
        // checking for other requests
        if (this.requestSent) return;
        this.requestSent = true;
        await new Promise((r) => setTimeout(r, 200));
        // sending request for contacts
        let res = await this.axios.post("/api/contacts/getcontacts", {
          options: this.options,
          filters: this.includeFilters,
        });
        this.requestSent = false;

        if (res.data.success) {
          this.contacts = res.data.contacts;
          this.contacts_length = res.data.count;
        }
      } catch (error) {
        this.requestSent = false;
        this.$store.dispatch("setSnackBar", "Contacts could not be loaded: check your internet connection");
        console.log(error);
      }
    },

    createItem() {
      this.newContact = true;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    // opening create/edit modal
    editItem(item) {
      this.newContact = false;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    // opening delete modal
    deleteItem(item) {
      this.deleteId = item.id;
      this.dialogDelete = true;
    },

    // delete conform function
    async deleteItemConfirm() {
      this.$store.dispatch("setSnackBar", "Deleting contact...");
      this.requestSent = true;
      try {
        if (!this.deleteId) return;
        let res = await this.axios.put("/api/contacts/contact/delete", { id: this.deleteId });
        this.requestSent = false;
        this.$store.dispatch("setSnackBar", res.data.message);
        this.closeDelete();
        await this.loadContacts();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.$store.dispatch("setSnackBar", "Contact could not be deleted: check your internet connection");
        this.closeDelete();
      }
    },

    // closing create/edit modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.dialogStatus = "";
      });
    },

    // closing delete modal
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      // resetting status
      this.dialogStatus = "";

      let contact = Object.assign({}, this.editedItem);
      // validating data
      if (!contact.firstName || !contact.lastName || !contact.mobile || contact.markets.length < 1) {
        this.$store.dispatch("setSnackBar", "Name, mobile number & markets should not be empty");
        return;
      }

      if (contact.mobile.length > 18) {
        this.$store.dispatch("setSnackBar", "Invalid mobile number");
        return;
      }

      console.log(contact);

      // setting up req body
      this.requestSent = true;

      if (!this.newContact) {
        try {
          // updating contact on basis of set index
          this.$store.dispatch("setSnackBar", "Updating contact...");

          let res = await this.axios.put("/api/contacts/contact/update", contact);
          this.requestSent = false;
          this.$store.dispatch("setSnackBar", res.data.message);
          if (!res.data.success) return;
          await this.initialize();
          this.close();
        } catch (error) {
          this.requestSent = false;
          this.$store.dispatch("setSnackBar", "Contact could not be updated: check your internet connection");
          console.log(error);
        }
      } else {
        // creating new contact on bases of index
        this.$store.dispatch("setSnackBar", "Creating contact...");
        try {
          let res = await this.axios.post("/api/contacts/create", contact);

          this.requestSent = false;
          this.$store.dispatch("setSnackBar", res.data.message);
          if (!res.data.success) return;
          await this.initialize();
          this.close();
        } catch (error) {
          this.requestSent = false;
          this.$store.dispatch("setSnackBar", "Contact could not be created: check your internet connection");
          console.log(error);
        }
      }
    },

    async getSuggestions(input_filter, input_value) {
      if (input_value.length < 3 || !input_filter.column || this.suggestionRequestSent || this.includeFiltersStoreSuggestions.includes(input_filter.column))
        return;
      this.suggestionRequestSent = true;
      let filter = Object.assign({}, input_filter);
      filter.value = input_value;
      let res = await this.axios.post("/api/contacts/getsuggestions", { filter });
      this.suggestionRequestSent = false;
      if (!res.data.success) return;
      this.suggestions = res.data.suggestions;
    },
    // getting suggestions or combobox-items for predefined values like markets
    async getSuggestionsFromStore(filter) {
      filter.value = [];
      this.suggestions = [];
      let column = filter.column;

      if (this.includeFiltersStoreSuggestions.includes(column)) {
        if (column.toLowerCase() == "markets") column = "suburb";
        this.suggestions = this.$store.getters.getFilterItems[column];
        if (column == "suburb") {
          this.suggestions = this.$store.getters.getFilterItems[column].map((suburb) => suburb.suburb + ", " + suburb.state + ", " + suburb.postcode);
        }
        if (column == "isOptOut") {
          this.suggestions = ["Yes", "No"];
        }
      }
    },
    // contact optout function
    contactOptOut(contact) {
      let self = this;
      this.$store.dispatch("setSnackBar", "Optout request sent for " + contact.name);
      this.axios
        .post("/api/contacts/contact/optout", { id: contact.id, optout: !contact.isOptOut })
        .then((res) => {
          self.$store.dispatch("setSnackBar", res.data.message);
          if (!res.data.success) return;
          contact.isOptOut = res.data.isOptOut;
        })
        .catch((err) => {
          self.$store.dispatch("setSnackBar", "Contact could not be opted out");
        });
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
td a {
  text-decoration: none;
}
::v-deep th.text-start {
  min-width: 130px;
  max-width: 300px;
}
::v-deep .v-dialog {
  border-radius: 0px !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
