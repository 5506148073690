<template>
  <!-- Data table for contct list: start -->
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <v-overlay :value="loading" style="z-index: 20001">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
      <v-sheet color="white" elevation="3" rounded class="d-flex align-start justify-center pa-2" style="min-height: 90vh; width: 100%">
        <v-data-table
          :headers="headers"
          :items="messages"
          :options.sync="options"
          :server-items-length="messages_length"
          item-key="id"
          show-expand
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          style="border: none; width: 100%"
          height="73vh"
          fixed-header
          dense
          :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
        >
          <template v-slot:top>
            <div class="d-flex flex-wrap justify-space-between align-center my-0 mb-3">
              <div></div>
              <h4 class="justify-self-center py-3">{{ title }}</h4>
              <div>
                <v-btn small color="primary" dark text outlined class="py-3" :to="{ name: 'Campaigns' }"><v-icon small>mdi-arrow-left</v-icon></v-btn>
                <v-btn small color="primary" dark text outlined class="py-3" @click="initialize()"><v-icon small>mdi-refresh</v-icon></v-btn>
              </div>
            </div>
          </template>

          <template v-slot:no-data> No campaigns to load </template>

          
          <template v-slot:[`item.sendAt`]="{ item }">
            <span class="text-capitalize">{{ item.sendAt | formatDate }}</span>
          </template>

          <template v-slot:[`item.mobile`]="{ item }">
            <span class="text-capitalize">{{ item.to_mobile }}</span>
          </template>

          
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <span class="text-capitalize">{{ item.day }}</span>
          </template> -->

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <!-- <v-flex md-2>Markets:</v-flex> -->
                <v-col cols="12" md="9">
                  <v-row class="pa-8">
                    <div>{{ item.message }}</div>
                  </v-row>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-sheet>
      <!-- Data table for contct list: end -->
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  name: "SMSDetails",
  data: () => ({
    // new fields
    title: "",
    campaign_id: null,
    messages: [],
    messages_length: 0,
    headers: [
      { text: "Name", align: "left", value: "contact.name", sortable: false },
      { text: "Mobile", value: "mobile", sortable: false },
      { text: "Cost", value: "cost" },
      { text: "Send Time", value: "sendAt" },
      { text: "Delievery Time", value: "dlrTime" },
      { text: "Status", value: "dlrStatus" },
      { text: "SMS Parts", value: "smsParts" },
      { text: "Message", value: "data-table-expand", sortable: false },
    ],
    options: {
      page: 1,
      itemsPerPage: 50,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    requestSent: false,
    singleExpand: true,
    expanded: [],
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    options() {
      this.loadSMS();
    },
  },
  mounted() {
    this.title = this.$route.query.title;
    this.campaign_id = this.$route.query.campaign_id;
    this.initialize();
  },

  methods: {
    // new methods
    async initialize() {
      this.options.page = 1;
      await this.loadSMS();
    },
    // new functions for component
    async loadSMS() {
      try {
        // checking for other requests
        if (this.requestSent) return;
        this.requestSent = true;

        // sending request for contacts
        const res = await this.axios.post("/api/bsms/getsmscampaigndetails", {
          options: this.options,
          campaign_id: this.campaign_id,
        });
        this.requestSent = false;
        console.log(res.data);
        if (res.data.success) {
          this.messages = res.data.messages;
          this.messages_length = res.data.count;
        }
      } catch (error) {
        this.requestSent = false;
        this.$store.dispatch("setSnackBar", "Messages could not be fetched - Check you connection");
        console.log(error);
      }
    },
  },
  filters: {
    formatDate: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style scoped>
::v-deep th.text-start {
  min-width: 100px;
  max-width: 300px;
}
::v-deep td {
  font-size: 13px !important;
  font-weight: 500;
  cursor: pointer;
}
</style>
