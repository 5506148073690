<template>
  <!-- Data table for contct list: start -->
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
          <span class="pa-2 text-center" style="line-height: 2em">Processing...</span>
        </div>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar v-model="showSnackbar" timeout="-1" :vertical="true" app bottom right style="opacity: 1; z-index: 2005" content-class="main-snackbar-content" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="reply_dialog" max-width="500px" width="500px" persistent>
      <v-card class="pa-5 rounded-2">
        <div class="profile-div">
          <h5 class="text-center pb-2">Quick Reply</h5>

          <v-select v-model="from_number" :items="numbers" label="Send From" dense solo hide-details class="rounded-2 my-3"></v-select>
          <v-text-field v-model="to_number" label="Send To" dense solo hide-details class="rounded-2 my-3"></v-text-field>
          <v-textarea v-model="message" label="Type message" dense solo hide-details class="rounded-2 my-3"></v-textarea>

          <div class="d-flex justify-end py-0 my-0 mx-0 px-0 dialogs-div">
            <v-btn color="white" class="mx-1 my-1" small elevation="2" @click="reply_dialog = false">
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn color="primary" class="mx-0 my-1" small elevation="2" :disabled="request_sent" @click="send_quick_reply">
              <v-icon class="mr-1" small>mdi-content-save</v-icon>
              Send
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
      <v-sheet color="white" elevation="3" rounded class="d-flex flex-column align-start justify-center pa-2" style="min-height: 90vh; width: 100%">
        <div class="d-flex flex-wrap justify-space-between align-center my-0 mb-3 w-100">
          <div></div>
          <h4 class="justify-self-center">{{ table_title }}</h4>
          <div>
            <v-btn small color="primary" dark text outlined class="py-4" :to="{ name: 'Campaigns' }"><v-icon small>mdi-arrow-left</v-icon></v-btn>
            <v-btn small color="primary" dark text outlined class="py-4" @click="initialize()"><v-icon small>mdi-refresh</v-icon></v-btn>
          </div>
        </div>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="items_count"
          item-key="id"
          show-expand
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          style="border: none; width: 100%"
          height="73vh"
          fixed-header
          dense
          :footer-props="{ 'items-per-page-options': [10, 50, 100, 250, 500] }"
        >
          <template v-slot:top> </template>
          <template v-slot:no-data> {{ request_sent ? "Loading data" : "No data found" }} </template>

          <template v-slot:[`item.response`]="{ item }">
            <span>{{ format_long_text(item.response) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-center justify-start">
              <v-icon color="primary" small class="" @click="open_reply_dialog(item)"> mdi-reply </v-icon>
            </div>
            <!-- <v-icon color="red" small class="mr-2" @click="() => {}"> mdi-delete </v-icon> -->
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12" md="9">
                  <v-row class="pa-10">
                    <div><b>Response:</b> {{ item.response }}</div>
                  </v-row>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  name: "SMSInbox",
  data: () => ({
    previous_page_component: "",
    table_title: "Inbox",
    items: [],
    items_count: 0,
    headers: [
      { text: "From", value: "msisdn" },
      { text: "Recived Datetime", value: "received_at", sortable: false },
      { text: "Response", value: "response" },
      { text: "Message", value: "data-table-expand", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    options: {
      page: 1,
      itemsPerPage: 50,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    singleExpand: true,
    expanded: [],

    // loading fields
    request_sent: false,
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    // replydialog fields
    reply_dialog: false,
    from_number: "",
    to_number: "",
    message: "",
    numbers: ["+61480008208"],
  }),
  computed: {
    loading() {
      return this.request_sent;
    },
  },

  watch: {
    options() {
      this.loadTableData();
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    // new methods
    async initialize() {
      this.options.page = 1;
      await this.loadTableData();
    },
    // new functions for component
    async loadTableData() {
      try {
        // checking for other request_sents
        if (this.request_sent) return;
        this.request_sent = true;

        // validating data
        await new Promise((r) => setTimeout(r, 2000));

        // sending request_sent for data
        const res = await this.axios.post("/api/bsms/getsmsinboxmessages", {
          options: this.options,
        });

        this.request_sent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);

        this.items = res.data.items;
        this.items_count = res.data.count;
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message || "There was an error while trying to get data, kindly check your connection");
      }
    },

    async send_quick_reply() {
      try {
        // validating data
        if (!this.from_number) throw new Error("Select a number to send from");
        if (!this.to_number) throw new Error("Add a number to send message to");
        if (!this.message) throw new Error("Type a message to send");
        this.request_sent = true;

        // sending request_sent for data
        await new Promise((r) => setTimeout(r, 2000));
        const res = await this.axios.post("/api/bsms/sendquickreply", {
          from: this.from_number,
          to: this.to_number,
          message: this.message,
        });

        // handling response
        this.request_sent = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", res.data.message);
        this.reply_dialog = false;
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message || "Unable to send reply, check your connection");
      }
    },
    open_reply_dialog(item) {
      this.to_number = item.msisdn;
      this.reply_dialog = true;
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    format_long_text(str) {
      const max_length = 40;
      if (!str || str.length <= max_length) {
        return str;
      } else {
        return str.substring(0, max_length).trim() + "...";
      }
    },
  },
  filters: {
    formatDate: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
::v-deep th.text-start {
  min-width: 100px;
  max-width: 300px;
}
::v-deep td {
  font-size: 13px !important;
  font-weight: 500;
  cursor: pointer;
}
.dialogs-div .v-btn {
  text-transform: initial;
  width: 146px;
  float: right;
}
.dialogs-div .v-btn .v-icon {
  width: 20px;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
