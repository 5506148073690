<template>
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
          <span class="pa-2 text-center" style="line-height: 2em">Processing...</span>
        </div>
        <div class="py-2 d-flex align-baseline justify-center"></div>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar v-model="showSnackbar" timeout="2000" :vertical="true" app top right style="opacity: 1; z-index: 2005" content-class="main-snackbar-content" elevation="3">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template v-if="typeof snackbarText2 == 'string'">
          <div>{{ snackbarText2 }}</div>
        </template>
        <template v-else>
          <template v-for="(value, key) in snackbarText2">
            <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</div>
          </template>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-col cols="12">
      <v-sheet color="white" elevation="3" rounded class="d-flex align-center justify-center" style="min-height: 90vh">
        <div class="d-flex justify-space-around flex-wrap" style="width: 100%">
          <v-card max-width="500px" width="500px" class="pa-5 rounded-2">
            <div class="profile-div">
              <h5 class="text-center">Profile</h5>
              <v-text-field v-model="user.firstName" label="First Name" required dense solo clearable hide-details class="rounded-2 py-0 my-3"></v-text-field>
              <v-text-field v-model="user.lastName" label="Last Name" required dense solo clearable hide-details class="rounded-2 py-0 my-3"></v-text-field>
              <v-text-field v-model="user.email" :disabled="true" label="Email" dense solo clearable hide-details class="rounded-2 py-0 my-3"></v-text-field>

              <v-select dense v-model="user.image" :items="avatars" item-text="name" item-value="path" label="Select Avatar" solo class="rounded-2 py-0 mb-6 text-capitalize" hide-details>
                <template v-slot:selection="{ item }">
                  <v-img height="100" contain :src="require('@/assets/avatars/' + item.path)"></v-img>
                </template>
                <template v-slot:item="{ item }">
                  <!-- <img :src="`@/assets/avatars/avatar1.png`" /> -->
                  <v-img height="100" contain :src="require('@/assets/avatars/' + item.path)"></v-img>
                </template>
              </v-select>

              <v-col cols="12" class="d-flex justify-end py-0 my-0 mx-0 px-0">
                <v-btn color="white" class="mx-1 my-1" small elevation="2">
                  <v-icon class="mr-1" small>mdi-cancel</v-icon>
                  Cancel
                </v-btn>

                <v-btn color="white" class="mx-0 my-1" small elevation="2" :disabled="requestSent" @click="update_profile()">
                  <v-icon class="mr-1" small>mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>

              <!-- <v-btn color="warning" @click="resetValidation">
            Reset Validation
          </v-btn> -->
            </div>
          </v-card>

          <v-card max-width="550px" width="500px" class="pa-5 rounded-2">
            <div class="profile-div">
              <h5 class="text-center">Password</h5>

              <v-text-field v-model="passwords.currentPassword" type="password" label="Current Password" required dense solo clearable class="rounded-2 my-3" hide-details></v-text-field>
              <v-text-field v-model="passwords.newPassword" type="password" label="New Password" required dense solo clearable class="rounded-2 my-3" hide-details></v-text-field>
              <v-text-field v-model="passwords.repeatNewPassword" type="password" label="Repeat New Password" dense solo clearable class="rounded-2 my-3" hide-details></v-text-field>

              <v-col class="d-flex justify-end mx-0 pa-0 pt-3">
                <v-btn color="white" class="mx-1 my-1" small elevation="2">
                  <v-icon class="mr-1" small>mdi-cancel</v-icon>
                  Cancel
                </v-btn>

                <v-btn color="white" class="mx-0 my-1" small elevation="2" :disabled="requestSent" @click="update_password()">
                  <v-icon class="mr-1" small>mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>
            </div>
          </v-card>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "UserSettings",
  data: () => ({
    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    requestSent: false,
    user: {},

    // images
    selected_avatar: "",
    avatars: [
      { name: "avatar9", path: "avatar9.png" },
      { name: "avatar8", path: "avatar8.png" },
      { name: "avatar7", path: "avatar7.png" },
      { name: "avatar6", path: "avatar6.png" },
      { name: "avatar5", path: "avatar5.png" },
      { name: "avatar4", path: "avatar4.png" },
      { name: "avatar3", path: "avatar3.png" },
      { name: "avatar2", path: "avatar2.png" },
    ],

    passwords: { currentPassword: "", newPassword: "", repeatNewPassword: "" },
  }),
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  mounted() {
    this.user = Object.assign({}, this.$store.getters.getUser);
    if (!this.user.id) this.status = "There was an error fetching user data";
  },
  methods: {
    update_profile() {
      if (!this.user.firstName || !this.user.lastName || !this.user.email) {
        return this.snackbarShow(true, "Error", "Kindly check all fields");
      }

      this.requestSent = true;

      this.axios
        .put("/api/users/user/editself", this.user)
        .then((res) => {
          this.requestSent = false;
          this.status = res.data.message;
          if (!res.data.success) return;
          this.$store.dispatch("setUser", this.user);
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.status = "There was an error in updating the user, kindly check the connection.";
        });
    },
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    update_password() {
      if (!this.passwords.currentPassword || !this.passwords.newPassword || !this.passwords.repeatNewPassword) {
        return this.snackbarShow(true, "Error", "Kindly fill in all fields");
      }

      if (this.passwords.newPassword !== this.passwords.repeatNewPassword) {
        return this.snackbarShow(true, "Error", "Passwords do not match");
      }

      this.requestSent = true;

      this.axios
        .put("/api/users/user/changeselfpassword", this.passwords)
        .then((res) => {
          this.requestSent = false;
          this.snackbarShow(true, "Info", res.data.message);
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.snackbarShow(true, "Error", "There was an error in updating the password, kindly check your connection.");
        });
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}

.profile-div .v-btn {
  text-transform: initial;
  width: 146px;
  float: right;
}
.profile-div .v-btn .v-icon {
  width: 20px;
}
</style>
